import { deserialize, list, primitive, serializable } from 'serializr';

export class TreemapItem {
  @serializable
  size: number = 0;

  @serializable
  name?: string = '';

  @serializable
  model?: string = '';

  @serializable(list(primitive()))
  technologies?: string[] | null = null;

  static deserialize(json: Object | string): TreemapItem {
    return deserialize(TreemapItem, json);
  }
}

import { useMutation } from '@tanstack/react-query';
import { fetchQuestionsByChatId } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Message } from 'entities/Message.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useQuestionsByChatId = (
  id: string,
  cb: (data: Message[]) => void
) => {
  const snackbar = useSnackbar();

  return useMutation<Message[], ApiError, unknown>({
    mutationFn: () => fetchQuestionsByChatId(id),
    onSuccess(data) {
      cb(data);
      queryClient.setQueryData(queryKeys.chatQuestions(id), data);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as GieniLogo } from 'assets/logo-confirm.svg';
import { AuthLayout, Card, SubTitle, Title } from 'components';
import { Routes } from 'enums';
import { useContactGieniUrl } from 'hooks';

import styles from './styles.module.scss';

export const ResetPasswordConfirm: FC = () => {
  const { t } = useTranslation();
  const contactGieniUrl = useContactGieniUrl();

  return (
    <AuthLayout>
      <Card className={styles.card}>
        <>
          <GieniLogo className={styles.logo} />
          <Title
            className={styles.title}
            title={t('Page.Auth.ResetPasswordConfirm.Title')}
          />
          <SubTitle className={styles.subtitle}>
            {t('Page.Auth.ResetPasswordConfirm.Subtitle')}
          </SubTitle>
          <Link to={Routes.NewChat} className={styles['button-container']}>
            <Button
              color="primary"
              size="medium"
              type="submit"
              variant="contained"
            >
              {t('Page.Auth.ResetPasswordConfirm.Button')}
            </Button>
          </Link>

          <span
            className={styles['support-info']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('Page.Auth.ResetPasswordConfirm.SupportInfo', {
                url: contactGieniUrl
              })
            }}
          />
        </>
      </Card>
    </AuthLayout>
  );
};

import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AuthLayout,
  Card,
  SubTitle,
  Title,
  VerifyUserForm,
  VerifyUserFormData
} from 'components';
import { Routes } from 'enums';
import { useUpdateUserInfo } from 'hooks/api/useUpdateUserInfo';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { LocalStorage } from 'services/LocalStorage';
import { APP_UI_LANGUAGE, DEFAULT_PREFERRED_LANGUAGE } from 'utils/constants';

import styles from './styles.module.scss';

export const VerifyUser: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: userInfo, isLoading: isUserInfoLoading } = useUserInfo();
  const { mutate: updateUserInfo, isPending: isUserInfoUpdating } =
    useUpdateUserInfo();

  const methods = useForm<VerifyUserFormData>({
    defaultValues: {
      lastName: ''
    }
  });

  const handleSubmit = useCallback(
    ({ lastName }: VerifyUserFormData) => {
      const trimedLastName = lastName.trim();
      const preferredLanguage: string =
        LocalStorage.getItem(APP_UI_LANGUAGE) || DEFAULT_PREFERRED_LANGUAGE;

      updateUserInfo({
        lastName: trimedLastName,
        preferredLanguage
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateUserInfo, userInfo?.firstName]
  );

  useEffect(() => {
    if (userInfo?.lastName) {
      navigate(Routes.NewChat);
    }
  }, [navigate, userInfo]);

  return !isUserInfoLoading ? (
    <AuthLayout>
      <Card>
        <Title className={styles.title} title={t('Page.VerifyUser.Title')} />
        <SubTitle className={styles.subtitle}>
          {t('Page.VerifyUser.Subtitle', {
            firstName: userInfo?.firstName || ''
          })}
        </SubTitle>
        <FormProvider {...methods}>
          <VerifyUserForm
            onSubmit={handleSubmit}
            isLoading={isUserInfoUpdating}
            submitLabel={t('Page.VerifyUser.Submit')}
          />
        </FormProvider>
      </Card>
    </AuthLayout>
  ) : null;
};

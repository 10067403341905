import React, { FC } from 'react';
import cn from 'classnames';
import { Tooltip } from 'components';
import { useIsDashboardAccessible } from 'hooks';

import styles from './styles.module.scss';

interface Payload {
  dataKey: string;
  name: string;
  value: number | string;
  type?: string;
  payload: {
    className: string;
    cx: string;
    cy: string;
    fill: string;
    name: string;
    payload: {
      name: string;
      value: number;
    };
    radius: number;
    stroke: string;
    strokeWidth: number;
    value: number;
  };
}

interface Props {
  payload?: Payload[];
  active?: boolean;
}

export const PieChartTooltip: FC<Props> = ({ payload, active }) => {
  const isDashboardAccessible = useIsDashboardAccessible();

  if (!active || !payload?.length) {
    return null;
  }

  return (
    <Tooltip>
      <div
        className={cn(styles.tooltip, !isDashboardAccessible && styles.hidden)}
      >
        <span className={styles.title}>{payload[0].name}</span>
        <div className={styles.container}>
          <span
            className={styles.color}
            style={{ backgroundColor: payload[0].payload.fill }}
          />
          <span className={styles.subtitle}>{`${payload[0].value}%`}</span>
        </div>
      </div>
    </Tooltip>
  );
};

import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { Button, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as GieniIcon } from 'assets/GieniIcon.svg';
import { ReactComponent as ThumbDownIcon } from 'assets/thumbs-down.svg';
import { ReactComponent as ThumbUpIcon } from 'assets/thumbs-up.svg';
import cn from 'classnames';
import { FeedbackModal } from 'components';
import { Message } from 'entities/Message.entity';
import { FeedbackTypes, queryKeys, Routes } from 'enums';
import { MessageTypes } from 'enums/MessageTypes.enum';
import {
  useRateChatMessage,
  useUserAvatar,
  useUserCoins,
  useUserInfo
} from 'hooks/api';
import { queryClient } from 'index';
import Markdown from 'markdown-to-jsx';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { BLUR_TRIGGER } from 'utils/constants';

import { UpgradePopup } from '../UpgradePopup';

import styles from './styles.module.scss';

interface Props {
  data: Message;
  isLoading?: boolean;
  isLastAnswer?: boolean;
  isMessageSending?: boolean;
  isRateButtonHidden?: boolean;
  interruptMessage?: () => void;
  isDashboardButtonHidden?: boolean;
  isRegenerateMessageSending?: boolean;
  onShowDashboardClick?: (messageId: string) => void;
  regenerateChatMessage?: (messageId: string) => void;
}

export const ChatMessage: FC<Props> = ({
  data: {
    id,
    type,
    statement,
    hasFeedback,
    isPaid,
    isNotFull,
    messageId,
    isInterrupted,
    hasDashboardData,
    hasUpgradeButton
  },
  isLoading,
  isMessageSending,
  isLastAnswer,
  interruptMessage,
  isRegenerateMessageSending,
  onShowDashboardClick,
  regenerateChatMessage,
  isDashboardButtonHidden,
  isRateButtonHidden
}) => {
  const upgradeBlockRef = useRef<HTMLDivElement | null>(null);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  const [isUpgradeBlockVisible, setIsUpgradeBlockVisible] = useState(false);
  const [givenFeedback, setGivenFeedback] = useState<FeedbackTypes | null>(
    null
  );
  const [userAvatarUrl, setUserAvatarUrl] = useState<string | undefined>();
  const { data: userAvatar } = useUserAvatar();

  // const isLimitedAnswer =
  //   type === MessageTypes.answer && statement.includes(BLUR_TRIGGER);
  const isLimitedAnswer = false;

  const { chatId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const { mutate: rateChatMessage } = useRateChatMessage(
    chatId || '',
    messageId || ''
  );

  const hasError = id?.includes('error');

  const openFeedbackModal = (feedback: FeedbackTypes | null) => {
    if (!hasFeedback && !isMessageSending && !isRegenerateMessageSending) {
      setGivenFeedback(feedback);
    }
  };

  const closeFeedbackModal = () => {
    if (!hasFeedback) {
      setGivenFeedback(null);
    }
  };

  const submitFeedback = (feedback: FeedbackTypes, feedbackText?: string) => {
    mixpanel?.track(MixpanelEvents.AnswerFeedback, {
      Feedback: feedback,
      'Feedback Text': feedbackText,
      'Chat ID': chatId,
      'Message ID': messageId,
      Text: statement
    });
    rateChatMessage({ text: feedbackText, feedbackType: feedback });
  };

  const handleShowDashboardClick = (messageId?: string) => () => {
    if (onShowDashboardClick && messageId) {
      onShowDashboardClick(messageId);
    }
  };

  const regenerateMessage = (messageId?: string) => () => {
    if (regenerateChatMessage && messageId) {
      regenerateChatMessage(messageId);
    }
  };

  const handleUpgradeToPremiumClick = () => {
    navigate(Routes.SubscriptionPlans, {
      state: { scrollToTop: true }
    });
  };

  useEffect(() => {
    if (userAvatar) {
      const imageUrl = URL.createObjectURL(userAvatar);

      setUserAvatarUrl(imageUrl);
    } else {
      setUserAvatarUrl(undefined);
    }

    return () => {
      if (userAvatarUrl) {
        URL.revokeObjectURL(userAvatarUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAvatar]);

  const renderCaret = () =>
    isLastAnswer && isLoading && <div className={styles.caret} />;

  const stopMessage = () => {
    if (!chatId) {
      return;
    }

    if (interruptMessage) {
      interruptMessage();
    }

    const prevMessages: Message[] | undefined = queryClient.getQueryData(
      queryKeys.chatMessages(chatId)
    );

    if (!prevMessages) return;

    const updatedMessages = prevMessages?.map((message) => {
      if (message.messageId === messageId) {
        return {
          ...message,
          isNotFull: true
        };
      }
      return message;
    });

    if (updatedMessages?.length) {
      queryClient.setQueryData(queryKeys.chatMessages(chatId), updatedMessages);
    }
  };

  const [usefulStatement, blurredStatement] = useMemo(() => {
    let processedStatement = statement;

    processedStatement = processedStatement.replace(
      /(```|markdown)/g,
      ''
    ) as string;

    // eslint-disable-next-line prefer-const
    let [firstPart, secondPart] = processedStatement.split(BLUR_TRIGGER);

    return [firstPart, secondPart];
  }, [statement]);

  useEffect(() => {
    if (
      messageContainerRef.current &&
      upgradeBlockRef.current &&
      isLimitedAnswer
    ) {
      const offset = 100;
      const blurredWrapperHeight = messageContainerRef.current.offsetHeight;
      const upgradeBlockHeight = upgradeBlockRef.current.offsetHeight + offset;

      setIsUpgradeBlockVisible(blurredWrapperHeight > upgradeBlockHeight);
    }

    if (!isLimitedAnswer) {
      setIsUpgradeBlockVisible(false);
    }
  }, [statement, isLimitedAnswer]);

  const showUpgradePopup = isUpgradeBlockVisible && !userInfo?.deactivatedAt;
  const hideRateButton =
    isMessageSending || isRegenerateMessageSending || isRateButtonHidden;
  const showDashboardButtonVisible =
    hasDashboardData &&
    !isDashboardButtonHidden &&
    !isMessageSending &&
    !isRegenerateMessageSending &&
    !isNotFull;
  const restartGenerationButtonVisible =
    isLastAnswer &&
    !isMessageSending &&
    !isRegenerateMessageSending &&
    isNotFull;
  const stopGenerationButtonVisible =
    isLastAnswer && (isMessageSending || isRegenerateMessageSending);
  const disableDashboard =
    !isPaid &&
    (userInfo?.deactivatedAt || (userInfo?.isFreeUser && !userCoins?.count));
  const actionsVisible =
    showDashboardButtonVisible ||
    restartGenerationButtonVisible ||
    stopGenerationButtonVisible;

  const getUserAvatar = () => {
    return userAvatarUrl ? (
      <img
        className={styles['avatar-image']}
        src={userAvatarUrl}
        alt="avatar"
      />
    ) : (
      <PersonIcon />
    );
  };

  return (
    <div
      ref={messageContainerRef}
      data-testid="chat-message"
      className={cn(styles.message, styles[type])}
    >
      {!!givenFeedback && (
        <FeedbackModal
          feedback={givenFeedback}
          onClose={closeFeedbackModal}
          onSubmitFeedback={submitFeedback}
        />
      )}
      <div className={styles['message-body']}>
        <div className={styles.avatar}>
          {type === MessageTypes.question ? getUserAvatar() : <GieniIcon />}
        </div>

        <div className={styles['content-container']}>
          <div className={styles.content}>
            <Markdown>{usefulStatement}</Markdown>
            {!blurredStatement?.length && renderCaret()}

            {blurredStatement && (
              <div className={cn(styles['blurred-wrapper'], styles.visible)}>
                <div
                  className={cn(
                    styles.overlay,
                    blurredStatement && styles.visible
                  )}
                />
                <div className={styles.blurred}>
                  <Markdown>{blurredStatement}</Markdown>
                </div>
                {renderCaret()}
              </div>
            )}
          </div>

          {isInterrupted &&
            (type === MessageTypes.answer ||
              type === MessageTypes.followup_questions) && (
              <span className={styles['interrupted-note']}>
                {t('Page.Chat.InterruptedNote')}
              </span>
            )}

          {hasUpgradeButton && (
            <Button
              size="medium"
              color="primary"
              variant="contained"
              className={cn(styles.button, 'brilliance')}
              onClick={handleUpgradeToPremiumClick}
            >
              {t('Page.Chat.UpgradeToPremium')}
            </Button>
          )}

          <div
            className={cn(
              styles.footer,
              !actionsVisible && styles['end-aligned']
            )}
          >
            {actionsVisible && (
              <div className={styles.actions}>
                {showDashboardButtonVisible && (
                  <Tooltip
                    disableHoverListener={!disableDashboard}
                    disableTouchListener={!disableDashboard}
                    disableFocusListener={!disableDashboard}
                    title={
                      !userCoins?.count
                        ? t('Common.FreeSubscriptionFullDashboardButtonTooltip')
                        : t('Common.UnlockCoinsModal.DeactivatedAccountTooltip')
                    }
                    enterTouchDelay={0}
                    PopperProps={{
                      disablePortal: true
                    }}
                    classes={{
                      tooltip: styles.tooltip
                    }}
                  >
                    <span>
                      <Button
                        size="medium"
                        color="primary"
                        variant="contained"
                        disabled={!!disableDashboard}
                        className={cn(styles['action-button'], 'brilliance')}
                        onClick={handleShowDashboardClick(messageId)}
                      >
                        {t(
                          !isPaid && userInfo?.isFreeUser
                            ? 'Page.Chat.ForDashboard'
                            : 'Page.Chat.ForPaidDashboard'
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                )}
                {stopGenerationButtonVisible && (
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    onClick={stopMessage}
                    className={cn(styles['action-button'], 'brilliance')}
                  >
                    {t('Page.Chat.StopGeneration')}
                  </Button>
                )}
                {restartGenerationButtonVisible && (
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    onClick={regenerateMessage(messageId)}
                    className={cn(styles['action-button'], 'brilliance')}
                  >
                    {t('Page.Chat.RestartGeneration')}
                  </Button>
                )}
              </div>
            )}
            {type === MessageTypes.answer && !hideRateButton && !hasError && (
              <div data-html2canvas-ignore className={styles['rate-container']}>
                <span className={styles.label}>
                  {t('Page.Chat.RateAnswer')}
                </span>
                <Tooltip
                  disableHoverListener={!hasFeedback}
                  disableTouchListener={!hasFeedback}
                  disableFocusListener={!hasFeedback}
                  title={t('Common.FeedbackModal.DisabledTooltip')}
                  enterTouchDelay={0}
                  PopperProps={{
                    disablePortal: true
                  }}
                  classes={{
                    tooltip: styles.tooltip
                  }}
                >
                  <IconButton
                    edge="end"
                    className={cn(
                      styles['rate-container-button'],
                      hasFeedback && styles.disabled
                    )}
                    onClick={() => openFeedbackModal(FeedbackTypes.NEGATIVE)}
                  >
                    <ThumbDownIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  disableHoverListener={!hasFeedback}
                  disableTouchListener={!hasFeedback}
                  disableFocusListener={!hasFeedback}
                  title={t('Common.FeedbackModal.DisabledTooltip')}
                  enterTouchDelay={0}
                  PopperProps={{
                    disablePortal: true
                  }}
                  classes={{
                    tooltip: styles.tooltip
                  }}
                >
                  <IconButton
                    edge="end"
                    className={cn(
                      styles['rate-container-button'],
                      hasFeedback && styles.disabled
                    )}
                    onClick={() => openFeedbackModal(FeedbackTypes.POSITIVE)}
                  >
                    <ThumbUpIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <UpgradePopup ref={upgradeBlockRef} isVisible={showUpgradePopup} />
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { FC, useMemo } from 'react';
import { Themes } from 'enums';
import { useMobile } from 'hooks/useMobile';
import {
  Bar,
  BarChart as BarChartComponent,
  Brush,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { BarChartThemeColors, barChartTickFormatter } from '../BarChart';
import { BarChartTooltip } from '../BarChartTooltip';

import './styles.scss';

interface Props {
  unit: string;
  theme: Themes.Success | Themes.Warning;
  data?: Array<{ name: string; value?: number | number[] | null }>;
}

export const HorizontalBarChart: FC<Props> = ({ data = [], unit, theme }) => {
  const isMobile = useMobile();

  const domainValues: [number, number] | undefined = useMemo(() => {
    if (!data) return undefined;

    const values = data.map(({ value }) =>
      Array.isArray(value) ? 0 : value || 0
    );

    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    return [minValue, maxValue];
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChartComponent
        width={500}
        data={data}
        margin={{
          top: 10,
          right: 10,
          bottom: 0,
          left: 0
        }}
      >
        <CartesianGrid stroke="#8f8f8f" strokeDasharray="2" vertical={false} />
        <XAxis
          hide={isMobile}
          tickLine={false}
          dataKey="name"
          type="category"
          tick={{
            fill: '#AFAFAF',
            fontSize: 11,
            fontFamily: 'Figtree, sans-serif',
            style: {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }
          }}
        />
        <YAxis
          domain={domainValues}
          tickLine={false}
          axisLine={false}
          type="number"
          tickFormatter={(value) => barChartTickFormatter(value, unit)}
          tick={{
            fill: '#AFAFAF',
            fontSize: 11,
            fontFamily: 'Figtree, sans-serif'
          }}
        />
        <Tooltip
          cursor={false}
          trigger="hover"
          // eslint-disable-next-line react/no-unstable-nested-components
          content={(props) => <BarChartTooltip {...props} unit={unit} />}
        />
        <Brush
          data-html2canvas-ignore
          dataKey="name"
          height={22}
          fill="#444657"
          stroke="#777A8C"
          travellerWidth={8}
          startIndex={0}
          endIndex={9}
        />
        <Bar
          width={500}
          dataKey="value"
          barSize={24}
          radius={[4, 4, 0, 0]}
          fill={BarChartThemeColors[theme]}
        >
          {(data || []).map((entry) => (
            <Cell
              key={entry.name}
              fill="#7DEAB2"
              style={{
                transition: 'all 0.2s ease-in-out'
              }}
            />
          ))}
        </Bar>
      </BarChartComponent>
    </ResponsiveContainer>
  );
};

import React, { FC } from 'react';
import cn from 'classnames';
import { Themes } from 'enums';

import styles from './styles.module.scss';

interface Props {
  label: string;
  theme: Themes;
}

export const Badge: FC<Props> = ({ label, theme }) => (
  <div data-testid="badge" className={cn(styles.badge, styles[theme])}>
    {label}
  </div>
);

import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { confirmRestorePassword } from 'actions/authActions';
import { ApiError } from 'entities/ApiError.entity';
import { Routes } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';

export const useConfirmRestorePassword = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  return useMutation<
    void,
    ApiError,
    {
      token: string;
      password: string;
    }
  >({
    mutationFn: async ({ token, password }) => {
      await confirmRestorePassword({
        token,
        password
      });
    },
    onSuccess: () => {
      navigate(Routes.ConfirmRestorePassword);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};

import { useMutation } from '@tanstack/react-query';
import { updateWidgetsOrder } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useUpdateWidgetsOrder = (chatId: string, messageId: string) => {
  const snackbar = useSnackbar();

  return useMutation<
    void,
    ApiError,
    {
      sequence: {
        firstColumn: string[];
        secondColumn: string[];
      };
      isFreeUser?: boolean;
    }
  >({
    mutationFn: ({ sequence }) => {
      return updateWidgetsOrder({ chatId, messageId, sequence });
    },
    onError(error) {
      snackbar.error.commonError(error);
    },
    onSuccess(data, { sequence }) {
      const prevDashboardData: DashboardData | undefined =
        queryClient.getQueryData(queryKeys.dashboardDetails(messageId));

      if (!prevDashboardData) return;

      const { firstColumn, secondColumn } = sequence;

      const widgetsWithIds =
        prevDashboardData!.widgets?.reduce(
          (
            acc: Record<DashboardComponent['id'], DashboardComponent>,
            widget
          ) => {
            acc[widget.id] = widget;

            return acc;
          },
          {}
        ) || {};

      const firstColumnWidgets = firstColumn.map((widgetId) => ({
        ...widgetsWithIds[widgetId],
        column: 'first'
      }));

      const secondColumnWidgets = secondColumn.map((widgetId) => ({
        ...widgetsWithIds[widgetId],
        column: 'second'
      }));

      const hiddenWidgets =
        prevDashboardData!.widgets?.filter((widget) => widget.isHidden) || [];

      queryClient.setQueryData(queryKeys.dashboardDetails(messageId!), {
        ...prevDashboardData,
        sequence,
        widgets: [
          ...firstColumnWidgets,
          ...secondColumnWidgets,
          ...hiddenWidgets
        ]
      });
    }
  });
};

import { SubscriptionPlans } from 'enums';
import { date, deserialize, list, primitive, serializable } from 'serializr';

export class User {
  @serializable
  id: string = '';

  @serializable
  customerId: string | null = null;

  @serializable
  email: string = '';

  @serializable
  firstName: string = '';

  @serializable
  lastName: string = '';

  @serializable
  avatarFilename: string | null = null;

  @serializable
  subscriptionPlan: SubscriptionPlans = SubscriptionPlans.Free;

  @serializable
  preferredLanguage: string = '';

  @serializable
  emailConfirmed: boolean = false;

  @serializable(list(primitive()))
  provider: string[] | null = null;

  @serializable(date())
  createdAt: Date | null = null;

  @serializable(date())
  deactivatedAt: Date | null = null;

  @serializable
  isFirstLogin: boolean | null = null;

  @serializable
  isBeta: boolean | null = null;

  @serializable
  showReleaseNotes: boolean | null = null;

  get isFreeUser(): boolean {
    return this.subscriptionPlan === SubscriptionPlans.Free;
  }

  get isPremiumUser(): boolean {
    return this.subscriptionPlan === SubscriptionPlans.Premium;
  }

  static deserialize(json: Object | string): User {
    return deserialize(User, json);
  }
}

import { Message } from 'entities/Message.entity';
import { MessageTypes } from 'enums/MessageTypes.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'index';

export interface StreamData {
  id?: string;
  messageId?: string;
  statement: string;
  type: MessageTypes;
  hasUpgradeButton?: boolean;
}

export const useAnswerFromStream = () => {
  const setAnswerStatementFromStream =
    (currentChatId: string) =>
    ({ id, messageId, statement, type, hasUpgradeButton }: StreamData) => {
      const previousMessages = queryClient.getQueryData(
        queryKeys.chatMessages(currentChatId || '')
      ) as Message[];

      const isAnswerRecordExists = previousMessages?.find(
        (message) => message.id === id && message.type === type
      );

      if (!isAnswerRecordExists || id == null) {
        const tempAnswerData = {
          id,
          type,
          statement,
          messageId,
          hasUpgradeButton
        };

        queryClient.setQueryData(
          queryKeys.chatMessages(currentChatId || ''),
          (previousMessages: Message[]) => [
            tempAnswerData,
            ...(previousMessages || [])
          ]
        );
      } else {
        queryClient.setQueryData(
          queryKeys.chatMessages(currentChatId || ''),
          (previousMessages: Message[]) =>
            (previousMessages || []).map((previousMessage) => {
              if (previousMessage.id === id) {
                return {
                  ...previousMessage,
                  statement
                };
              }

              return previousMessage;
            })
        );
      }
    };

  return { setAnswerStatementFromStream };
};

import { useNavigate } from 'react-router-dom';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { createChat } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Chat } from 'entities/Chat.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useCreateChat = (
  chatId: string | undefined,
  sendMessageMutation: UseMutateFunction<
    {
      id: string;
      messageId: string;
      statement: string;
      hasDashboardData: boolean;
    } | void,
    ApiError,
    {
      message: string;
      currentChatId: string;
    },
    unknown
  >
) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  return useMutation<Chat, ApiError, { message: string }>({
    mutationFn: createChat,
    onSuccess: (data, { message }) => {
      if (!chatId) {
        navigate(Routes.Chat.replace(':chatId', data.id), {
          replace: true
        });
      }
      sendMessageMutation({ message, currentChatId: data.id });
      queryClient.invalidateQueries({
        queryKey: queryKeys.filteredChats({ includeArchived: false })
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.filteredChats({ includeArchived: true })
      });
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};

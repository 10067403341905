import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Collapse } from '@mui/material';
import cn from 'classnames';

import { QuestionCard } from './QuestionCard';

import styles from './styles.module.scss';

export const questions = [
  {
    title: 'Page.Pricing.Questions.List.Third.Title',
    description: 'Page.Pricing.Questions.List.Third.Description',
    button: 'Page.Pricing.Questions.List.Third.Button'
  },
  {
    title: 'Page.Pricing.Questions.List.Fourth.Title',
    description: 'Page.Pricing.Questions.List.Fourth.Description'
  },
  {
    title: 'Page.Pricing.Questions.List.Fifth.Title',
    description: 'Page.Pricing.Questions.List.Fifth.Description'
  },
  {
    title: 'Page.Pricing.Questions.List.Sixth.Title',
    description: 'Page.Pricing.Questions.List.Sixth.Description'
  },
  {
    title: 'Page.Pricing.Questions.List.Seventh.Title',
    description: 'Page.Pricing.Questions.List.Seventh.Description'
  },
  {
    title: 'Page.Pricing.Questions.List.Eighth.Title',
    description: 'Page.Pricing.Questions.List.Eighth.Description'
  },
  {
    title: 'Page.Pricing.Questions.List.Nineth.Title',
    description: 'Page.Pricing.Questions.List.Nineth.Description'
  },
  {
    title: 'Page.Pricing.Questions.List.Ten.Title',
    description: 'Page.Pricing.Questions.List.Ten.Description'
  },
  {
    title: 'Page.Pricing.Questions.List.Eleventh.Title',
    description: 'Page.Pricing.Questions.List.Eleventh.Description'
  }
];

export const Questions = () => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleQuestions = useCallback(() => {
    setExpanded((value) => !value);
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>{t('Page.Pricing.Questions.Title')}</h2>
      <div className={styles.cards}>
        {questions.slice(0, 4).map(({ title, description, button }) => (
          <QuestionCard
            key={title}
            isExpanded
            title={t(title)}
            description={t(description)}
            buttonTitle={button ? t(button) : ''}
          />
        ))}
        <Collapse
          className={styles.collapse}
          unmountOnExit
          in={expanded}
          timeout="auto"
          classes={{
            wrapperInner: styles.collapse
          }}
        >
          {questions.slice(4).map(({ title, description, button }) => (
            <QuestionCard
              key={title}
              title={t(title)}
              description={t(description)}
              buttonTitle={button ? t(button) : ''}
            />
          ))}
        </Collapse>
        <Button
          size="medium"
          color="secondary"
          variant="contained"
          onClick={toggleQuestions}
          className={styles.button}
          endIcon={
            <KeyboardArrowDownIcon
              className={cn(styles['arrow-icon'], expanded && styles.expanded)}
            />
          }
        >
          {t(expanded ? 'Common.ShowLess' : 'Common.ShowMore')}
        </Button>
      </div>
    </div>
  );
};

import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { TextField } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as CrossIcon } from 'assets/cross.svg';
import { Keys } from 'enums/KeyboardKeys';

import styles from './styles.module.scss';

interface Props {
  value: string;
  label: string;
  placeholder?: string;
  onBlur: (value: string) => void;
}

export const EditableTitle: FC<Props> = ({
  value,
  placeholder,
  label,
  onBlur
}) => {
  const [inputValue, setInputValue] = useState<string>(value);

  const onSubmit = useCallback(() => {
    if (!inputValue) {
      setInputValue(value);
    }
    onBlur(inputValue);
  }, [inputValue, onBlur, value]);

  const clearInput = () => setInputValue('');

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setInputValue(event.target.value);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.Enter) {
      onSubmit();
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      <TextField
        size="small"
        value={inputValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        id={label.toLowerCase()}
        placeholder={placeholder}
        className={styles.container}
        inputProps={{
          className: styles.input
        }}
      />
      <span onClick={clearInput}>
        <CrossIcon className={styles['action-icon']} />
      </span>
      <span onClick={onSubmit}>
        <CheckIcon className={styles['action-icon']} />
      </span>
    </>
  );
};

import { useMutation } from '@tanstack/react-query';
import { updateChatWidget } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useUpdateChatWidget = (chatId: string, messageId: string) => {
  const snackbar = useSnackbar();

  return useMutation<
    void,
    ApiError,
    {
      widgetId: DashboardComponent['id'];
      isHidden: boolean;
      isFreeUser?: boolean;
    }
  >({
    mutationFn: ({ isHidden, widgetId }) => {
      return updateChatWidget({
        widgetId,
        chatId: chatId || '',
        messageId: messageId || '',
        data: {
          isHidden
        }
      });
    },
    onError(error) {
      snackbar.error.commonError(error);
    },
    onSuccess(_, { widgetId, isHidden }) {
      const prevDashboardData: DashboardData | undefined =
        queryClient.getQueryData(queryKeys.dashboardDetails(messageId));

      if (!prevDashboardData) return;

      const updatedWidget =
        prevDashboardData.widgets?.find((widget) => widget.id === widgetId) ||
        [];

      const prevWidgets =
        prevDashboardData.widgets?.filter(({ id }) => id !== widgetId) || [];

      const updatedWidgets = isHidden
        ? [...prevWidgets, { ...updatedWidget, isHidden }]
        : [{ ...updatedWidget, isHidden }, ...prevWidgets];

      if (updatedWidgets?.length) {
        queryClient.setQueryData(queryKeys.dashboardDetails(messageId!), {
          ...prevDashboardData,
          widgets: updatedWidgets
        });
      }
    }
  });
};

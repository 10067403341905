import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { LocalStorage } from 'services/LocalStorage';
import { APP_UI_LANGUAGE, DEFAULT_PREFERRED_LANGUAGE } from 'utils/constants';

export const usePreferredLanguage = () => {
  const { i18n } = useTranslation();
  const { data: userInfo } = useUserInfo();

  useEffect(() => {
    const preferredLanguageFromLS: string =
      LocalStorage.getItem(APP_UI_LANGUAGE) || DEFAULT_PREFERRED_LANGUAGE;

    if (preferredLanguageFromLS !== userInfo?.preferredLanguage) {
      i18n.changeLanguage(
        userInfo?.preferredLanguage || preferredLanguageFromLS
      );
    }
  }, [userInfo?.preferredLanguage, i18n]);
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as CongratsIcon } from 'assets/congrats.svg';
import { Card, Footer, SubTitle, Title } from 'components';
import { format } from 'date-fns';
import { Routes, SubscriptionCyclesLabels } from 'enums';
import { useScheduledSubscription } from 'hooks';
import { useActiveSubscription } from 'hooks/api';

import { ProfileTabs } from '../Profile/Profile';

import styles from './styles.module.scss';

export const SubscriptionUpgradeConfirm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: subscriptionDetails } = useActiveSubscription();
  const scheduledSubscription = useScheduledSubscription();

  return (
    <>
      <div className={styles.container}>
        <Card>
          <CongratsIcon />
          <Title
            className={styles.title}
            title={t('Page.SubscriptionUpgradeConfirm.SuccessTitle')}
          />
          <SubTitle className={styles.subtitle}>
            {t('Page.SubscriptionUpgradeConfirm.SuccessSubtitle', {
              currentCycle: subscriptionDetails?.period
                ? t(SubscriptionCyclesLabels[subscriptionDetails?.period])
                : '',
              futureCycle: scheduledSubscription?.period
                ? t(SubscriptionCyclesLabels[scheduledSubscription?.period])
                : '',
              renewDate: subscriptionDetails?.expiresAt
                ? format(subscriptionDetails?.expiresAt, 'PP')
                : '-'
            })}
          </SubTitle>

          <div className={styles.actions}>
            <Button
              fullWidth
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() =>
                navigate(Routes.Profile.replace(':tab?', ProfileTabs.Billing))
              }
            >
              {t('Page.SubscriptionUpgradeConfirm.GoToBilling')}
            </Button>
            <Button
              fullWidth
              size="medium"
              color="primary"
              variant="contained"
              onClick={() => navigate(Routes.NewChat)}
            >
              {t('Page.SubscriptionUpgradeConfirm.BackToChatbot')}
            </Button>
          </div>
        </Card>
      </div>
      <Footer />
    </>
  );
};

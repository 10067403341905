import { ApiError } from 'entities/ApiError.entity';
import { AuthApi } from 'services/API/Auth/AuthApi';

export interface SigninUserData {
  email: string;
  password: string;
}

export interface SignupUserData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  preferredLanguage: string;
}

export const signIn = async ({
  email,
  password
}: SigninUserData): Promise<void> => {
  try {
    await AuthApi.signIn(email, password);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const signUp = async ({
  email,
  password,
  firstName,
  lastName,
  preferredLanguage
}: SignupUserData): Promise<void> => {
  try {
    await AuthApi.signUp({
      email,
      password,
      firstName,
      lastName,
      preferredLanguage
    });
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const confirmEmail = async (token: string): Promise<null> => {
  try {
    await AuthApi.confirmEmail(token);

    return null;
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateEmailConfirm = async (token: string): Promise<null> => {
  try {
    await AuthApi.updateEmailConfirm(token);

    return null;
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const resendEmail = async (email: string): Promise<void> => {
  try {
    await AuthApi.resendEmail(email);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const restorePassword = async (email: string): Promise<void> => {
  try {
    await AuthApi.restorePassword(email);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const logout = async (): Promise<void> => {
  try {
    await AuthApi.logout();
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const confirmRestorePassword = async ({
  token,
  password
}: {
  token: string;
  password: string;
}): Promise<void> => {
  try {
    await AuthApi.confirmRestorePassword({ token, password });
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@mui/material';
import { ReactComponent as NegativeIcon } from 'assets/negative.svg';
import { ReactComponent as NegativeActiveIcon } from 'assets/negative-active.svg';
import { ReactComponent as NeutralIcon } from 'assets/neutral.svg';
import { ReactComponent as NeutralActiveIcon } from 'assets/neutral-active.svg';
import { ReactComponent as PositiveIcon } from 'assets/positive.svg';
import { ReactComponent as PositiveActiveIcon } from 'assets/positive-active.svg';
import { Modal } from 'components';
import { FeedbackTypes } from 'enums';

import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  feedback: FeedbackTypes;
  onSubmitFeedback: (feedback: FeedbackTypes, feedbackText?: string) => void;
}
export const FeedbackModal: FC<Props> = ({
  onClose,
  feedback = FeedbackTypes.NEUTRAL,
  onSubmitFeedback
}) => {
  const { t } = useTranslation();
  const [selectedFeedback, setSelectedFeedback] =
    useState<FeedbackTypes>(feedback);
  const [inputValue, setInputValue] = useState<string>('');

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setInputValue(event.target.value);

  const onClick = () => {
    onClose();
    if (selectedFeedback) {
      onSubmitFeedback(selectedFeedback, inputValue);
    }
  };

  const onFeedbackIconClick = (feedback: FeedbackTypes) => {
    setSelectedFeedback(feedback);
    setInputValue('');
  };

  const data = [
    {
      id: FeedbackTypes.POSITIVE,
      icon: (
        <PositiveIcon
          className={styles.icon}
          onClick={() => onFeedbackIconClick(FeedbackTypes.POSITIVE)}
        />
      ),
      activeIcon: <PositiveActiveIcon />
    },
    {
      id: FeedbackTypes.NEUTRAL,
      icon: (
        <NeutralIcon
          className={styles.icon}
          onClick={() => onFeedbackIconClick(FeedbackTypes.NEUTRAL)}
        />
      ),
      activeIcon: <NeutralActiveIcon />
    },
    {
      id: FeedbackTypes.NEGATIVE,
      icon: (
        <NegativeIcon
          className={styles.icon}
          onClick={() => onFeedbackIconClick(FeedbackTypes.NEGATIVE)}
        />
      ),
      activeIcon: <NegativeActiveIcon />
    }
  ];

  return (
    <Modal
      onClose={onClose}
      isOpen={!!feedback}
      paperClassName={styles.modal11}
      titleClassName={styles.title}
      title={t('Common.FeedbackModal.Title')}
    >
      <div className={styles.icons}>
        {data.map(({ id, icon, activeIcon }) =>
          id === selectedFeedback ? activeIcon : icon
        )}
      </div>
      <p className={styles.subtitle}>{t('Common.FeedbackModal.Subtitle')}</p>
      <div>
        <div className={styles.label}>
          {t('Common.FeedbackModal.TextReason')}
        </div>
        <TextField
          rows={4}
          multiline
          size="small"
          value={inputValue}
          onChange={onChange}
          id="explained-reason"
          inputProps={{
            className: styles.input,
            maxLength: 270
          }}
          className={styles.container}
          placeholder={t('Common.FeedbackModal.OtherPlaceholder')}
        />
      </div>
      <div className={styles.button}>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="contained"
          onClick={onClick}
        >
          {t('Common.Submit')}
        </Button>
      </div>
    </Modal>
  );
};

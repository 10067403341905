import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props<T> {
  selectedId: T;
  elements: Array<{
    id: T;
    element: JSX.Element;
  }>;
  onChange: (id: T) => void;
}

export const LabeledSwitch = <T,>({
  selectedId,
  onChange,
  elements
}: Props<T>) => (
  <div className={styles.container}>
    {elements.map(({ id, element }, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        onClick={() => onChange(id)}
        className={cn(styles.tab, id === selectedId && styles.active)}
      >
        {element}
      </div>
    ))}
  </div>
);

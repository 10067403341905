import React, { FC, ReactNode } from 'react';
import { Tooltip } from 'components';

import styles from './styles.module.scss';

export type ValueType = number | string | Array<number | string>;
export type NameType = number | string;
type Formatter<TValue extends ValueType, TName extends NameType> = (
  value: TValue,
  name: TName,
  item: ChartPayload<TValue, TName>,
  index: number,
  payload: Array<ChartPayload<TValue, TName>>
) => [React.ReactNode, TName] | React.ReactNode;

export interface ChartPayload<
  TValue extends ValueType,
  TName extends NameType
> {
  type?: 'none';
  fill?: string;
  color?: string;
  formatter?: Formatter<TValue, TName>;
  name?: TName;
  value?: TValue;
  unit?: ReactNode;
  dataKey?: string | number;
  payload?: {
    root: {
      children: Array<any>;
      index: number;
      color?: string;
      brand?: string;
    };
    depth: number;
    x: number;
    y: number;
    fill: string;
    width: number;
    height: number;
    index: number;
    name: string;
    model?: string;
  };
  chartType?: string;
  stroke?: string;
  strokeDasharray?: string | number;
  strokeWidth?: number | string;
}

interface Props {
  unit: string;
  active?: boolean;
  focusBarName?: string;
  showTooltipOnFocus?: boolean;
  payload?: ChartPayload<ValueType, NameType>[];
}

export const BarChartTooltip: FC<Props> = ({
  payload,
  active,
  unit,
  showTooltipOnFocus,
  focusBarName
}) => {
  if (!active || !payload?.length || (!focusBarName && showTooltipOnFocus)) {
    return null;
  }

  return (
    <Tooltip>
      <span className={styles.title}>{payload[0].payload?.name}</span>
      <div className={styles.container}>
        <span
          className={styles.color}
          style={{ backgroundColor: payload[0]?.fill }}
        />
        <span className={styles.subtitle}>{`${payload[0].value}${unit}`}</span>
      </div>
    </Tooltip>
  );
};

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupIcon from '@mui/icons-material/Group';
import { ReactComponent as DollarIconGreen } from 'assets/Icon-dollar-green.svg';
import { ReactComponent as MachinesIconGreen } from 'assets/Icon-machine-green.svg';
import { ReactComponent as MachineIconOrange } from 'assets/Icon-machine-orange.svg';
import { ReactComponent as ManufacturesIconGreen } from 'assets/Icon-manufacture-green.svg';
import { ReactComponent as ManufacturesIconOrange } from 'assets/Icon-manufacture-orange.svg';

import { Themes } from './Themes.enum';

export enum BigNumberUnits {
  Manufacturers = 'Manufacturers',
  Machines = 'Machines',
  Money = 'Money',
  Employees = 'Employees',
  $ = '$'
}

export const BigNumberGreenIcons = Object.freeze({
  [BigNumberUnits.Manufacturers]: ManufacturesIconGreen,
  [BigNumberUnits.Machines]: MachinesIconGreen,
  [BigNumberUnits.Money]: AttachMoneyIcon,
  [BigNumberUnits.Employees]: GroupIcon,
  [BigNumberUnits.$]: DollarIconGreen
});

export const BigNumberOrangeIcons = Object.freeze({
  [BigNumberUnits.Manufacturers]: ManufacturesIconOrange,
  [BigNumberUnits.Machines]: MachineIconOrange,
  [BigNumberUnits.Money]: AttachMoneyIcon,
  [BigNumberUnits.Employees]: GroupIcon,
  [BigNumberUnits.$]: DollarIconGreen
});

export const BigNumberUnitsThemes = Object.freeze({
  [BigNumberUnits.Manufacturers]: Themes.Success,
  [BigNumberUnits.Machines]: Themes.Warning,
  [BigNumberUnits.Money]: Themes.Success,
  [BigNumberUnits.Employees]: Themes.Warning,
  [BigNumberUnits.$]: Themes.Success
});

export const BigNumberUnitsLabels = Object.freeze({
  [BigNumberUnits.Manufacturers]:
    'Page.Dashboard.WidgetLabels.BigNumberManufacturers',
  [BigNumberUnits.Machines]: 'Page.Dashboard.WidgetLabels.BigNumberMachines',
  [BigNumberUnits.Money]: 'Page.Dashboard.WidgetLabels.BigNumberMoney',
  [BigNumberUnits.Employees]: 'Page.Dashboard.WidgetLabels.BigNumberEmployees',
  [BigNumberUnits.$]: 'Page.Dashboard.WidgetLabels.BigNumberMoney'
});

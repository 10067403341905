export const STORAGE_KEY_PREFIX = 'GIENI_STORAGE_';
export const APP_UI_LANGUAGE = 'appUiLanguage';
export const CURRENT_USER_EMAIL_KEY = 'currentUserEmail';
export const USER_AUTHENTICATED = 'userAuthenticated';
export const DEFAULT_PREFERRED_LANGUAGE = 'en';

export const DAYS_BEFORE_AUTO_CANCELLATION = 7;

export const SHOW_LOGIN_MODAL = 'showLoginModal';

export const SHOW_UNPAID_SUBSCRIPTION_MODAL = 'showUnpaidSubscriptionModal';

export const SHOW_NEW_DEVELOPMENTS_TOAST = 'showNewDevelopmentsToast';

export const BLUR_TRIGGER = '[blurred-part-begin]';

import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { Chat } from 'components/Chat';
import { ChatInput } from 'components/ChatInput';
import { Message } from 'entities/Message.entity';
import { Routes } from 'enums/Routes';
import {
  useCreateChat,
  useCreateMessage,
  useMessages,
  useUserCoins,
  useUserInfo
} from 'hooks/api';
import { useFetchDashboardData } from 'hooks/api/useFetchDashboardData';
import { useRegenerateChatMessage } from 'hooks/api/useRegenerateChatMessage';
import { useGlobalShortcutsContext } from 'hooks/useGlobalShortcutsContext';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { handleAnimationSwitch } from 'utils/helpers/animationHelper';

import { Suggestions } from './Suggestions';

import styles from './styles.module.scss';

export const Conversation: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setChatId } = useGlobalShortcutsContext();
  const navigate = useNavigate();
  const previousChatId = useRef<string | undefined>();
  const { chatId } = useParams();
  const { data: coinsNumber } = useUserCoins();
  const { data: userInfo } = useUserInfo();
  const { mutate: fetchDashboardData } = useFetchDashboardData(
    chatId as string
  );

  const disableNewQuestions =
    !!userInfo?.deactivatedAt && location.pathname === Routes.NewChat;

  const abortController = useRef<AbortController | null>(null);
  const [isChatHidden, setIsChatHidden] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isLoadingAnimation, setIsLoadingAnimation] = useState(false);
  const [extendedMessages, setExtendedMessages] = useState<Message[] | null>(
    null
  );

  const interruptMessage = () => {
    abortController.current?.abort();
  };

  const {
    mutate: sendMessageMutation,
    isPending,
    isMessageSending
  } = useCreateMessage({
    abortController,
    setSearchValue,
    setIsLoadingAnimation
  });
  const {
    mutate: regenerateMessageMutation,
    isPending: isRegenerateMessageSending,
    isRegenerateMessageRequestPending
  } = useRegenerateChatMessage({
    abortController
  });
  const { mutate: createChatMutation } = useCreateChat(
    chatId,
    sendMessageMutation
  );
  const { data: messages, isLoading: isMessagesLoading } = useMessages(
    chatId,
    isPending
  );

  useEffect(() => {
    if (chatId) {
      setChatId(chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (
      !chatId ||
      (previousChatId?.current != null &&
        chatId &&
        chatId !== previousChatId?.current)
    ) {
      interruptMessage();
    }

    if (chatId) {
      previousChatId.current = chatId;
    }

    return () => {
      previousChatId.current = undefined;
    };
  }, [chatId]);

  useEffect(() => {
    if (messages && chatId) {
      setExtendedMessages(messages);
    }
  }, [messages, chatId]);

  useEffect(() => {
    if (chatId) {
      handleAnimationSwitch(true);
    } else {
      handleAnimationSwitch(false);
    }
  }, [chatId]);

  const askQuestion = (message: string) => {
    if (!chatId) {
      createChatMutation({ message });
    } else {
      sendMessageMutation({ message, currentChatId: chatId });
    }
  };

  const regenerateMessage = (messageId: string) => {
    if (chatId) {
      regenerateMessageMutation({ messageId, currentChatId: chatId });
    }
  };

  const isLoading =
    isPending || isLoadingAnimation || isRegenerateMessageRequestPending;

  const sendMixpanelQuestionsAskedEvent = () => {
    mixpanel?.track(MixpanelEvents.QuestionAsked, {
      'Question amount': coinsNumber?.requestsCount,
      'User plan': userInfo?.subscriptionPlan
    });
  };

  const handleSubmit = () => {
    if (searchValue && !isLoading) {
      mixpanel?.track(MixpanelEvents.QuestionSubmit, {
        Text: searchValue,
        'Chat ID': chatId
      });

      sendMixpanelQuestionsAskedEvent();
      askQuestion(searchValue);
    }
  };

  const handleSuggestionClick = (text: string) => {
    sendMixpanelQuestionsAskedEvent();
    setSearchValue(text);
    askQuestion(text);
  };

  const handleDashboardClick = (messageId: string) => {
    fetchDashboardData({ messageId });

    mixpanel?.track(MixpanelEvents.DashboardGenerated, {
      'Message ID': messageId,
      'Chat ID': chatId,
      'User plan': userInfo?.subscriptionPlan,
      'Questions asked': coinsNumber?.requestsCount
    });

    handleAnimationSwitch(false);
    setIsLoadingAnimation(true);
    setIsChatHidden(true);
    setSearchValue(t('Page.Chat.LoadingDashboard'));

    setTimeout(() => {
      setSearchValue('');
      setIsLoadingAnimation(false);

      const link = Routes.Dashboard.replace(':chatId', chatId || '').replace(
        ':messageId',
        messageId
      );

      navigate(link);
    }, 2500);
  };

  const renderChat = () => {
    if (!messages?.length || !extendedMessages?.length) return null;

    const isRateButtonHidden = !!userInfo?.deactivatedAt;

    return (
      <div
        className={cn(styles.chat, {
          [styles.hidden]: isChatHidden
        })}
      >
        <Chat
          isLoading={isLoading}
          isMessageSending={isMessageSending}
          interruptMessage={interruptMessage}
          regenerateChatMessage={regenerateMessage}
          isRateButtonHidden={isRateButtonHidden}
          messages={extendedMessages as Message[]}
          onShowDashboardClick={handleDashboardClick}
          isRegenerateMessageSending={isRegenerateMessageSending}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {!isMessagesLoading && (
        <div className={styles.content}>
          {!messages?.length && <h1>{t('Page.Chat.Title')}</h1>}

          <div className={styles.conversation}>
            <Suggestions
              disabled={disableNewQuestions}
              isVisible={!messages?.length && !isLoading}
              onSuggestionClick={handleSuggestionClick}
            />

            {renderChat()}

            <div className={styles.input}>
              <ChatInput
                value={searchValue}
                isLoading={isLoading}
                setValue={setSearchValue}
                onSubmit={handleSubmit}
                disabled={disableNewQuestions}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import React, { ReactNode } from 'react';
import {
  DraggableProvidedDragHandleProps,
  DropResult
} from 'react-beautiful-dnd';
import cn from 'classnames';
import { DashboardCard } from 'components';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import {
  BigNumberGreenIcons,
  BigNumberOrangeIcons,
  BigNumberUnits,
  BigNumberUnitsThemes,
  ChartUnitTypes,
  ChartUnitTypesThemes,
  Themes
} from 'enums';
import { DashboardColumnName } from 'enums/DashboardColumnName.enum';
import { InsightsType, InsightsTypesClasses } from 'enums/InsightsType.enum';
import {
  DashboardComponentExtended,
  DashboardWidgets,
  DNDColumns
} from 'views/Dashboard/Dashboard';

import { BarChart } from './BarChart';
import { BigNumber } from './BigNumber';
import { ChatHistory } from './ChatHistory';
import { ContactUs } from './ContactUs';
import { HistogramChart } from './HistogramChart';
import { ManufactureProfile } from './ManufactureProfile';
import { Map } from './Map';
import { ParticleCloud } from './ParticleCloud';
import { PieChart } from './PieChart';
import { TreemapChart } from './TreemapChart';

import styles from './styles.module.scss';

export const splitIntoDefaultColumns = (
  items: Record<
    'xsmall' | 'small' | 'medium' | 'large',
    DashboardComponentExtended[]
  >
): DashboardWidgets => {
  const firstColumn = [];
  const secondColumn = [];

  const contactUsWidgetIndex = items.medium?.findIndex(
    (widget) => widget.type === InsightsType.ContactUs
  );
  const contactUsWidget = items.medium?.[contactUsWidgetIndex];

  if (contactUsWidget) {
    items.medium.splice(contactUsWidgetIndex, 1);
  }

  const hasXSmallItems = items.xsmall?.length;
  const hasSmallItems = items.small?.length;
  const hasMediumItems = items.medium?.length;
  const hasLargeItems = items.large?.length;

  const xSmallMidpoint = hasXSmallItems
    ? Math.ceil(items.xsmall.length / 2)
    : 0;
  const smallMidpoint = hasSmallItems
    ? Math.ceil((items.small.length || 1) / 2)
    : 0;
  const mediumMidpoint = hasMediumItems
    ? Math.ceil((items.medium.length || 1) / 2)
    : 0;
  const largeMidpoint = hasLargeItems
    ? Math.ceil((items.large.length || 1) / 2)
    : 0;

  const xSmallFirstHalf = hasXSmallItems
    ? items.xsmall.slice(0, xSmallMidpoint)
    : [];
  const xSmallSecondHalf = hasXSmallItems
    ? items.xsmall.slice(xSmallMidpoint)
    : [];

  const smallFirstHalf = hasSmallItems
    ? items.small.slice(0, smallMidpoint)
    : [];
  const smallSecondHalf = hasSmallItems ? items.small.slice(smallMidpoint) : [];

  const mediumFirstHalf = hasMediumItems
    ? items.medium.slice(0, mediumMidpoint)
    : [];
  const mediumSecondHalf = hasMediumItems
    ? items.medium.slice(mediumMidpoint)
    : [];

  const largeFirstHalf = hasLargeItems
    ? items.large.slice(0, largeMidpoint)
    : [];
  const largeSecondHalf = hasLargeItems ? items.large.slice(largeMidpoint) : [];

  firstColumn.push(
    ...xSmallFirstHalf,
    ...smallFirstHalf,
    ...mediumFirstHalf,
    ...largeFirstHalf
  );
  secondColumn.push(
    ...xSmallSecondHalf,
    ...smallSecondHalf,
    ...mediumSecondHalf,
    ...largeSecondHalf
  );

  if (contactUsWidget) {
    let manufactureProfileWidgetIndex = firstColumn.findIndex(
      (widget) => widget.type === InsightsType.ManufacturerProfiles
    );

    if (manufactureProfileWidgetIndex !== -1) {
      firstColumn.splice(manufactureProfileWidgetIndex + 1, 0, contactUsWidget);
    } else {
      manufactureProfileWidgetIndex = secondColumn.findIndex(
        (widget) => widget.type === InsightsType.ManufacturerProfiles
      );

      if (manufactureProfileWidgetIndex !== -1) {
        secondColumn.splice(
          manufactureProfileWidgetIndex + 1,
          0,
          contactUsWidget
        );
      } else {
        firstColumn.unshift(contactUsWidget);
      }
    }
  }

  return { firstColumn, secondColumn };
};

export const splitIntoColumns = (widgets: DashboardComponentExtended[]) =>
  widgets.reduce(
    (
      acc: {
        firstColumn: Array<DashboardComponentExtended>;
        secondColumn: Array<DashboardComponentExtended>;
      },
      widget
    ) => {
      if (widget.column === DashboardColumnName.first) {
        acc.firstColumn.push(widget);
      } else {
        acc.secondColumn.push(widget);
      }

      return acc;
    },
    { firstColumn: [], secondColumn: [] }
  );

export const sortBySizeData = (widgets: DashboardComponentExtended[]) =>
  widgets.reduce(
    (acc: Record<string, DashboardComponentExtended[]>, widgets) => {
      acc[widgets.className] = acc[widgets.className]?.length
        ? [...acc[widgets.className], widgets]
        : [widgets];

      return acc;
    },
    {}
  );

export const mapWithClassData = (
  widgets: DashboardComponent[]
): DashboardComponentExtended[] =>
  widgets.map((item) => ({
    ...item,
    className: InsightsTypesClasses[item.type]
  }));

export const onDNDEnd = ({
  dndData,
  onUpdateOrder,
  visibleDashboardWidgets
}: {
  dndData: DropResult;
  visibleDashboardWidgets: DashboardWidgets;
  onUpdateOrder: (columns: {
    firstColumn: DashboardComponentExtended[];
    secondColumn: DashboardComponentExtended[];
  }) => void;
}) => {
  const { source, destination } = dndData;
  if (destination === undefined || destination === null) return null;
  if (
    source.droppableId === destination.droppableId &&
    destination.index === source.index
  )
    return null;

  const start = visibleDashboardWidgets[source.droppableId as DNDColumns];
  const end = visibleDashboardWidgets[destination.droppableId as DNDColumns];

  if (start === end) {
    // Move the item within the list
    // Start by making a new list without the dragged item
    const newList = start.filter(
      (_: DashboardComponentExtended, idx: number) => idx !== source.index
    );

    // Then insert the item at the right location
    newList.splice(destination.index, 0, start[source.index]);

    const updatedData = {
      ...visibleDashboardWidgets,
      [source.droppableId]: newList
    };

    // Update the state
    onUpdateOrder(updatedData);
  } else {
    // If start is different from end, we need to update multiple columns
    // Filter the start list like before
    const newStartList = start.filter(
      (_: DashboardComponentExtended, idx: number) => idx !== source.index
    );

    // Make a new end list array
    const newEndList = end;

    // Insert the item into the end list
    newEndList.splice(destination.index, 0, start[source.index]);

    const updatedData = {
      ...visibleDashboardWidgets,
      [source.droppableId]: newStartList,
      [destination.droppableId]: newEndList
    };

    onUpdateOrder(updatedData);
  }
};

export const getDashboardWidget = ({
  isPaid,
  widget,
  dragHandleProps,
  handleWidgetLoad,
  isDashboardDownloading,
  hideWidget
}: {
  widget: DashboardComponentExtended;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  isPaid?: boolean;
  isDashboardDownloading?: boolean;
  handleWidgetLoad: (type: InsightsType) => void;
  hideWidget: () => void;
}) => {
  const curComp: {
    data: ReactNode;
    className?: string;
    iconClassName?: string;
  } = {
    className: '',
    iconClassName: '',
    data: <div />
  };

  if (widget.type === InsightsType.PieChart) {
    curComp.data = <PieChart data={widget.params} />;
  } else if (widget.type === InsightsType.BarChart) {
    const type: ChartUnitTypes = widget.params.type || ChartUnitTypes.Materials;
    const theme = ChartUnitTypesThemes[type];
    curComp.data = <BarChart data={widget.params} theme={theme} />;
  } else if (widget.type === InsightsType.Chat) {
    curComp.data = <ChatHistory data={widget.params} />;
  } else if (widget.type === InsightsType.ContactUs) {
    curComp.data = <ContactUs />;
  } else if (widget.type === InsightsType.Histogram) {
    curComp.data = <HistogramChart data={widget.params} />;
  } else if (widget.type === InsightsType.Blob) {
    curComp.data = (
      <ParticleCloud
        data={widget}
        isDashboardDownloading={isDashboardDownloading}
      />
    );
  } else if (
    widget.type === InsightsType.Treemap ||
    widget.type === InsightsType.TreemapV2
  ) {
    curComp.data = <TreemapChart data={widget} isPaid={isPaid} />;
  } else if (widget.type === InsightsType.ManufacturerProfiles) {
    curComp.className = styles['no-padding'];
    curComp.data = <ManufactureProfile data={widget.params} />;
  } else if (widget.type === InsightsType.BigNumber) {
    const unit: BigNumberUnits =
      widget.params.unit || BigNumberUnits.Manufacturers;
    const theme = BigNumberUnitsThemes[unit];
    const Icon =
      theme === Themes.Warning
        ? BigNumberOrangeIcons[unit]
        : BigNumberGreenIcons[unit];
    curComp.data = (
      <BigNumber
        theme={theme}
        data={widget.params}
        icon={Icon ? <Icon className={styles.icon} /> : undefined}
      />
    );
    curComp.className = styles['big-number'];
    curComp.iconClassName = styles['dark-icon'];
  } else if (widget.type === InsightsType.MapDots) {
    curComp.data = <Map onLoad={handleWidgetLoad} data={widget} />;
  } else {
    curComp.data = widget.type;
  }

  return (
    <div key={widget.type} className={cn(styles.card, styles[widget.type])}>
      <DashboardCard
        id={widget.id}
        isPaid={isPaid}
        type={widget.type}
        filterCard={hideWidget}
        className={curComp.className}
        hasFeedback={widget.hasFeedback}
        dragHandleProps={dragHandleProps}
        iconClassName={curComp.iconClassName}
      >
        {curComp.data}
      </DashboardCard>
    </div>
  );
};

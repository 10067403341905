import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { SubTitle } from 'components/SubTitle';
import { Title } from 'components/Title';

import { AppLink } from '../AppLink';

import styles from './styles.module.scss';

interface Props {
  link: string;
}

export const NoMatch: FC<Props> = ({ link }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="container" className={styles.container}>
      <Title title="404" />
      <SubTitle>{t('Page.NoMatch.Subtitle')}</SubTitle>

      <AppLink data-testid="link" to={link}>
        <Button
          color="primary"
          size="medium"
          variant="contained"
          className={styles.button}
        >
          {t('Page.NoMatch.Button')}
        </Button>
      </AppLink>
    </div>
  );
};

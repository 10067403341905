import { date, deserialize, serializable } from 'serializr';

export class Chat {
  @serializable
  id: string = '';

  @serializable
  userId: string = '';

  @serializable
  title?: string = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  archivedAt: Date = new Date();

  @serializable
  dashboardsCount?: number = 0;

  static deserialize(json: Object | string): Chat {
    return deserialize(Chat, json);
  }
}

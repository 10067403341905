import { MapTypes } from 'enums';
import { DashboardColumnName } from 'enums/DashboardColumnName.enum';
import { InsightsType } from 'enums/InsightsType.enum';
import { deserialize, list, object, primitive, serializable } from 'serializr';

import { DashboardParams } from './DashboardParams.entity';

export class DashboardComponent {
  @serializable
  id: string = '';

  @serializable
  title?: string | null = null;

  @serializable
  column?: DashboardColumnName;

  @serializable
  isHidden?: boolean = false;

  @serializable
  hasFeedback?: boolean = false;

  @serializable
  mapType?: MapTypes;

  @serializable(object(DashboardParams))
  params = new DashboardParams();

  @serializable(list(primitive()))
  filters: string[] = [];

  @serializable
  type: InsightsType = InsightsType.BigNumber;

  static deserialize(json: Object | string): DashboardComponent {
    return deserialize(DashboardComponent, json);
  }
}

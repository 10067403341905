import { UserCoin } from 'entities/UserCoin.entity';

import { ApiBase } from '../ApiBase';

class DashboardApi extends ApiBase {
  fetchCoins() {
    // TODO: Change to Coin on BE
    return this.client.get<UserCoin>('/credits');
  }
}

const instance = new DashboardApi('');

export { instance as DashboardApi };

import { LocalStorage } from 'services/LocalStorage';
import { APP_UI_LANGUAGE, DEFAULT_PREFERRED_LANGUAGE } from 'utils/constants';

import { useUserInfo } from './api';

export const useLandingUrl = () => {
  const { data: userInfo } = useUserInfo();
  const [language] = (
    userInfo?.preferredLanguage ||
    (LocalStorage.getItem(APP_UI_LANGUAGE) as string) ||
    DEFAULT_PREFERRED_LANGUAGE
  ).split('-');

  return `${process.env.REACT_APP_LANDING_PAGE_URL}/${language}`;
};

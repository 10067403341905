import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ReactComponent as EyeBanIcon } from 'assets/of-eye-ban.svg';
import cn from 'classnames';
import { Title } from 'components/Title';

import { SubTitle } from '../SubTitle';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  onClick?: () => void;
}
export const EmptyState: FC<Props> = ({ className, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.container, className)}>
      <EyeBanIcon className={styles.icon} />
      <Title className={styles.title}>
        {t('Page.Dashboard.EmptyState.Title')}
      </Title>
      <SubTitle className={styles.subtitle}>
        {t('Page.Dashboard.EmptyState.Subtitle')}
      </SubTitle>
      <Button
        size="small"
        color="secondary"
        variant="contained"
        onClick={onClick}
      >
        {t('Page.Dashboard.EmptyState.Button')}
      </Button>
    </div>
  );
};

import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo
} from 'react';
import { isSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@mui/material';
import { ReactComponent as PDFIcon } from 'assets/PDFIcon.svg';
import { DashboardData } from 'entities/Dashboard.entity';
import { useMobile } from 'hooks';
import { useUpdateChatWidget, useUserInfo } from 'hooks/api';
import { useSaveDashboard } from 'hooks/useSaveDashboard';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { DashboardComponentExtended, DashboardWidgets } from '../Dashboard';
import { Filters } from '../Filters';

import styles from './styles.module.scss';

interface Props {
  chatId?: string;
  messageId?: string;
  dashboardData?: DashboardData;
  isWidgetsLoaded: boolean;
  isAllWidgetsHidden: boolean;
  hiddenDashboardWidgets: DashboardWidgets;
  setIsDashboardDownloading: Dispatch<SetStateAction<boolean>>;
}

export const DashboardToolbar: FC<Props> = ({
  chatId,
  messageId,
  dashboardData,
  isWidgetsLoaded,
  isAllWidgetsHidden,
  hiddenDashboardWidgets,
  setIsDashboardDownloading
}) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { saveDashboard } = useSaveDashboard();
  const { mutate: changeChatVisibility } = useUpdateChatWidget(
    chatId || '',
    messageId || ''
  );

  const { data: userInfo } = useUserInfo();

  const downloadDashboardTooltip = useMemo(() => {
    if (userInfo?.deactivatedAt) {
      return t('Page.Dashboard.DownloadDashboardDeactivatedAccountHint');
    }

    return isSafari
      ? t('Page.Dashboard.DownloadDashboardSafariHint')
      : t('Page.Dashboard.DownloadDashboardHint');
  }, [t, userInfo?.deactivatedAt]);

  const disableDownload =
    isAllWidgetsHidden ||
    !dashboardData?.isPaid ||
    !isWidgetsLoaded ||
    isSafari ||
    !!userInfo?.deactivatedAt;

  const handleDownloadDashboardClick = () => {
    mixpanel?.track(MixpanelEvents.DownloadDashboardClick, {
      Title: dashboardData?.title,
      'Created at': dashboardData?.createdAt
    });
    const screenshotTarget = document.getElementById(`dashboard-${messageId}`);

    if (screenshotTarget) {
      setIsDashboardDownloading(true);

      setTimeout(() => {
        saveDashboard(
          screenshotTarget,
          setIsDashboardDownloading,
          dashboardData?.title,
          dashboardData?.createdAt
        );
      }, 2000);
    }
  };

  const showHiddenWidget = useCallback(
    (visibleWidget: DashboardComponentExtended) => {
      changeChatVisibility({
        widgetId: visibleWidget.id,
        isHidden: false
      });
    },
    [changeChatVisibility]
  );

  return (
    <div data-html2canvas-ignore className={styles.toolbar}>
      {!isMobile && (
        <Tooltip
          title={downloadDashboardTooltip}
          enterTouchDelay={0}
          placement="bottom-start"
          PopperProps={{
            disablePortal: true
          }}
          classes={{
            tooltip: styles['download-dashboard-tooltip']
          }}
        >
          <div className={styles['download-dashboard-wrapper']}>
            <Button
              disabled={disableDownload}
              onClick={handleDownloadDashboardClick}
              className={styles['download-dashboard']}
            >
              <PDFIcon />
              {t('Page.Dashboard.DownloadDashboard')}
            </Button>
          </div>
        </Tooltip>
      )}

      <Filters
        data={hiddenDashboardWidgets}
        onShowHiddenWidget={showHiddenWidget}
      />
    </div>
  );
};

import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  title?: string;
  className?: string;
}

export const Title: FC<Props> = ({ title, children, className }) => (
  <h1 className={cn(styles.title, className)}>{title || children}</h1>
);

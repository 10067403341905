export enum InsightsType {
  Histogram = 'histogram',
  Chat = 'chat',
  Blob = 'blob',
  MapDots = 'map_dots',
  Treemap = 'treemap',
  TreemapV2 = 'treemap2',
  PieChart = 'pie_chart',
  BarChart = 'bar_chart',
  BigNumber = 'big_number',
  ManufacturerProfiles = 'manufacturer_profiles',
  ContactUs = 'contact_us'
}

export const InsightsTypeToReadable = Object.freeze({
  [InsightsType.Chat]: 'Chat History',
  [InsightsType.Blob]: 'Particle Cloud',
  [InsightsType.MapDots]: 'Map',
  [InsightsType.Treemap]: 'Treemap',
  [InsightsType.TreemapV2]: 'Treemap V2',
  [InsightsType.PieChart]: 'Pie Chart',
  [InsightsType.BarChart]: 'Bar Chart',
  [InsightsType.BigNumber]: 'Big Number',
  [InsightsType.Histogram]: 'Histogram',
  [InsightsType.ManufacturerProfiles]: 'Manufacturer Profiles',
  [InsightsType.ContactUs]: 'Contact Us'
});

export const InsightsTypesClasses = Object.freeze({
  [InsightsType.Chat]: 'large',
  [InsightsType.Blob]: 'medium',
  [InsightsType.MapDots]: 'medium',
  [InsightsType.Treemap]: 'medium',
  [InsightsType.TreemapV2]: 'medium',
  [InsightsType.PieChart]: 'small',
  [InsightsType.BarChart]: 'small',
  [InsightsType.Histogram]: 'small',
  [InsightsType.BigNumber]: 'xsmall',
  [InsightsType.ManufacturerProfiles]: 'large',
  [InsightsType.ContactUs]: 'medium'
});

export const InsightsTypeLabels = Object.freeze({
  [InsightsType.Chat]: 'Page.Dashboard.WidgetLabels.Chat',
  [InsightsType.Blob]: 'Page.Dashboard.WidgetLabels.Blob',
  [InsightsType.MapDots]: 'Page.Dashboard.WidgetLabels.MapDots',
  [InsightsType.Treemap]: 'Page.Dashboard.WidgetLabels.Treemap',
  [InsightsType.TreemapV2]: 'Page.Dashboard.WidgetLabels.TreemapV2',
  [InsightsType.BarChart]: 'Page.Dashboard.WidgetLabels.BarChart',
  [InsightsType.Histogram]: 'Page.Dashboard.WidgetLabels.Histogram',
  [InsightsType.BigNumber]:
    'Page.Dashboard.WidgetLabels.BigNumberManufacturers',
  [InsightsType.PieChart]: 'Page.Dashboard.WidgetLabels.PieChartMachineBrands',
  [InsightsType.ManufacturerProfiles]:
    'Page.Dashboard.WidgetLabels.ManufacturerProfiles',
  [InsightsType.ContactUs]: 'Page.Dashboard.WidgetLabels.ContactUs'
});

import React, { FC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Checkbox, Menu, MenuItem } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/check-small.svg';
import { ReactComponent as UncheckIcon } from 'assets/uncheck-small.svg';
import cn from 'classnames';
import { useIsDashboardAccessible } from 'hooks';

import styles from './styles.module.scss';

export interface Option {
  value: any;
  label: string;
  color: string;
}

interface Props {
  values: string[];
  options: Option[];
  onChange(values: string[]): void;
}

const allOption = {
  value: 'all',
  label: 'All',
  color: 'transparent'
};

export const Select: FC<Props> = ({ values, options, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isDashboardAccessible = useIsDashboardAccessible();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onChange([...values, e.target.value]);
    } else {
      onChange(values.filter((value) => value !== e.target.value));
    }
  };

  const onToggleAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onChange(options.map(({ value }) => value));
    } else {
      onChange([]);
    }
  };

  const renderMenuItem = (
    option: Option,
    checked: boolean,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  ) => (
    <MenuItem disableRipple key={option.value} className={styles['menu-item']}>
      <div className={styles.content}>
        <Checkbox
          color="primary"
          size="small"
          value={option.label}
          icon={<UncheckIcon />}
          onChange={onChange || onChangeCheckbox}
          checkedIcon={<CheckIcon />}
          checked={checked}
          classes={{
            root: styles['checkbox-container'],
            sizeSmall: styles['checkbox-input']
          }}
        />
        <span className={cn(styles['checkbox-label'], 'overflowed-text')}>
          {option.label}
        </span>
      </div>
      <span
        className={styles.color}
        style={{
          backgroundColor: option.color
        }}
      />
    </MenuItem>
  );

  return (
    <div className={styles.container}>
      <Button
        disableElevation
        variant="contained"
        aria-haspopup="true"
        onClick={handleClick}
        className={styles.button}
        disabled={!isDashboardAccessible}
        endIcon={<KeyboardArrowDownIcon />}
        aria-expanded={open ? 'true' : undefined}
      >
        Filter
      </Button>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: styles.paper,
          list: styles.menu
        }}
      >
        {renderMenuItem(
          allOption,
          !!values.length && values.length === options.length,
          onToggleAll
        )}
        {options.map((option) =>
          renderMenuItem(option, values.includes(option.value))
        )}
      </Menu>
    </div>
  );
};

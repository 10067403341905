import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchCoins } from 'actions/coinActions';
import cn from 'classnames';
import { EmptyState, Footer, LoadingOverlay } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { InsightsType, queryKeys, Routes } from 'enums';
import { useDNDStrictMode, useGlobalShortcutsContext } from 'hooks';
import {
  useExposeAllWidgets,
  useUpdateChatWidget,
  useUpdateWidgetsOrder,
  useUserInfo
} from 'hooks/api';
import { useFetchDashboardData } from 'hooks/api/useFetchDashboardData';
import { queryClient } from 'index';
import { handleAnimationSwitch } from 'utils/helpers/animationHelper';

import { DashboardHeader } from './DashboardHeader';
import {
  getDashboardWidget,
  mapWithClassData,
  onDNDEnd,
  sortBySizeData,
  splitIntoColumns,
  splitIntoDefaultColumns
} from './dashboardHelpers';
import { DashboardToolbar } from './DashboardToolbar';

import styles from './styles.module.scss';

export enum DNDColumns {
  first = 'firstColumn',
  second = 'secondColumn'
}

export interface DashboardWidgets {
  firstColumn: DashboardComponentExtended[];
  secondColumn: DashboardComponentExtended[];
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  boxShadow: isDragging ? '0 5px 10px 0 rgb(0 0 0 / 8%)' : 'none',
  ...draggableStyle
});

export type DashboardComponentExtended = DashboardComponent & {
  className: string;
  iconClassName?: string;
};

export type WidgetLoadingState = { type: InsightsType; loaded: boolean };

const widgetsToWaitForLoad = [InsightsType.MapDots];

export const Dashboard: FC = () => {
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const { isDragAndDropEnabled } = useDNDStrictMode();
  const [isDashboardDownloading, setIsDashboardDownloading] = useState(false);
  const [isDashboardPaid, setIsDashboardPaid] = useState(false);
  const [widgetsLoadingState, setWidgetsLoadingState] = useState<
    WidgetLoadingState[]
  >([]);

  const isWidgetsLoaded = useMemo(
    () =>
      widgetsLoadingState.length
        ? widgetsLoadingState.every((widget) => widget.loaded)
        : true,
    [widgetsLoadingState]
  );

  const handleWidgetLoad = (type: InsightsType) =>
    setWidgetsLoadingState((widgets) =>
      widgets.map((w) => {
        if (w.type === type) {
          return {
            ...w,
            loaded: true
          };
        }

        return w;
      })
    );
  const { setChatId } = useGlobalShortcutsContext();

  const dashboardRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { messageId, chatId } = useParams();

  const { mutate: exposeAllWidgets } = useExposeAllWidgets(
    chatId || '',
    messageId || ''
  );

  if (!messageId) navigate(Routes.NewChat);

  const [visibleDashboardWidgets, setVisibleDashboardWidgets] =
    useState<DashboardWidgets>({ firstColumn: [], secondColumn: [] });
  const [hiddenDashboardWidgets, setHiddenDashboardWidgets] =
    useState<DashboardWidgets>({ firstColumn: [], secondColumn: [] });

  const visibleWidgets = useMemo(
    () => [
      ...visibleDashboardWidgets.firstColumn,
      ...visibleDashboardWidgets.secondColumn
    ],
    [visibleDashboardWidgets]
  );

  const {
    isError,
    isPending,
    isSuccess,
    mutate: fetchDashboardData
  } = useFetchDashboardData(chatId as string);

  useEffect(() => {
    if (isError) {
      navigate(Routes.NewChat);
    }
  }, [isError, navigate]);

  useEffect(() => {
    if (messageId) {
      fetchDashboardData({ messageId });
    }
  }, [fetchDashboardData, messageId]);

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(messageId!)
  });

  const { mutate: updateWidgetsOrderMutation } = useUpdateWidgetsOrder(
    chatId || '',
    messageId || ''
  );

  const { mutate: changeChatVisibility } = useUpdateChatWidget(
    chatId || '',
    messageId || ''
  );

  const shuffledData = useCallback(
    (widgets: DashboardComponentExtended[]): DashboardWidgets =>
      splitIntoDefaultColumns(sortBySizeData(widgets)),
    []
  );

  const { visible, hidden } = useMemo(
    () =>
      mapWithClassData(dashboardData?.widgets || []).reduce(
        (
          acc: {
            visible: Array<DashboardComponentExtended>;
            hidden: Array<DashboardComponentExtended>;
          },
          widget
        ) => {
          if (widget.isHidden) {
            acc.hidden.push(widget);
          } else {
            acc.visible.push(widget);
          }

          return acc;
        },
        { visible: [], hidden: [] }
      ),
    [dashboardData?.widgets]
  );

  const getGroupedData = useCallback(
    (widgets: DashboardComponentExtended[]) => {
      if (!widgets.length) {
        return {
          firstColumn: [],
          secondColumn: []
        };
      }
      if (dashboardData?.sequence) {
        return splitIntoColumns(widgets);
      }

      return shuffledData(widgets);
    },
    [dashboardData?.sequence, shuffledData]
  );

  useEffect(() => {
    if (isSuccess && userInfo?.isFreeUser) {
      queryClient.invalidateQueries({ queryKey: queryKeys.userCoins });
      queryClient.fetchQuery({
        queryKey: queryKeys.userCoins,
        queryFn: fetchCoins
      });
    }
  }, [messageId, isSuccess, userInfo?.isFreeUser]);

  useEffect(() => {
    if (dashboardData?.widgets?.length && visibleDashboardWidgets) {
      const widgetsToWait: WidgetLoadingState[] = [];

      visibleWidgets.forEach((widget) => {
        if (widgetsToWaitForLoad.includes(widget.type)) {
          const widgetToWaitForLoad = {
            type: widget.type,
            loaded: false
          };

          widgetsToWait.push(widgetToWaitForLoad);
        }
      });

      setWidgetsLoadingState(widgetsToWait);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData?.widgets, visibleWidgets]);

  useEffect(() => {
    if (chatId) {
      setChatId(chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    setVisibleDashboardWidgets(getGroupedData(visible));
    setHiddenDashboardWidgets(getGroupedData(hidden));
    setIsDashboardPaid(!!dashboardData?.isPaid);
  }, [
    hidden,
    shuffledData,
    dashboardData,
    visible,
    getGroupedData,
    dashboardData?.isPaid
  ]);

  useEffect(() => {
    if (dashboardData) handleAnimationSwitch(true);
  }, [dashboardData]);

  const hideWidget = useCallback(
    (hiddenWidget: DashboardComponentExtended) => {
      changeChatVisibility({
        widgetId: hiddenWidget.id,
        isHidden: true
      });
    },
    [changeChatVisibility]
  );

  const updateOrder = useCallback(
    (columns: DashboardWidgets) => {
      setVisibleDashboardWidgets(columns);
      updateWidgetsOrderMutation({
        sequence: {
          firstColumn: columns.firstColumn.map(({ id }) => id),
          secondColumn: columns.secondColumn.map(({ id }) => id)
        }
      });
    },
    [updateWidgetsOrderMutation]
  );

  const onDragEnd = useCallback(
    (dndData: DropResult) => {
      onDNDEnd({
        dndData,
        visibleDashboardWidgets,
        onUpdateOrder: updateOrder
      });
    },
    [updateOrder, visibleDashboardWidgets]
  );

  const getCard = (
    widget: DashboardComponentExtended,
    dragHandleProps: DraggableProvidedDragHandleProps | null | undefined
  ) =>
    getDashboardWidget({
      widget,
      dragHandleProps,
      isPaid: isDashboardPaid,
      isDashboardDownloading,
      handleWidgetLoad,
      hideWidget: () => hideWidget(widget)
    });

  return (
    <>
      {isDashboardDownloading && (
        <Backdrop className={styles.overlay} open={isDashboardDownloading}>
          <div className={styles['overlay-content']}>
            {t('Page.Dashboard.DownloadingDashboard')}
            <CircularProgress
              thickness={6}
              className={styles.progress}
              data-testid="chat-progress"
            />
          </div>
        </Backdrop>
      )}
      <div
        ref={dashboardRef}
        id={`dashboard-${messageId}`}
        className={cn(
          styles['dashboard-wrapper'],
          isDashboardDownloading && 'dashboard-preview'
        )}
      >
        {dashboardData?.title && (
          <DashboardHeader
            title={dashboardData.title}
            createdAt={dashboardData.createdAt}
          />
        )}

        <div className={styles.dashboard}>
          <LoadingOverlay loading={isPending}>
            <>
              <DashboardToolbar
                chatId={chatId}
                messageId={messageId}
                isWidgetsLoaded={isWidgetsLoaded}
                isAllWidgetsHidden={!visibleWidgets.length}
                dashboardData={dashboardData}
                hiddenDashboardWidgets={hiddenDashboardWidgets}
                setIsDashboardDownloading={setIsDashboardDownloading}
              />

              <DragDropContext onDragEnd={onDragEnd}>
                {isDragAndDropEnabled ? (
                  <div className={styles['main-container']}>
                    {[DNDColumns.first, DNDColumns.second].map((column) => (
                      <Droppable droppableId={column} key={column}>
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={styles.container}
                          >
                            {visibleDashboardWidgets[column].map(
                              (widget, index) => (
                                <Draggable
                                  key={widget.id}
                                  index={index}
                                  draggableId={widget.id.toString()}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      {getCard(
                                        widget,
                                        provided.dragHandleProps
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    ))}
                  </div>
                ) : null}
              </DragDropContext>
              {!visibleDashboardWidgets?.firstColumn?.length &&
                !visibleDashboardWidgets?.secondColumn?.length &&
                !isPending && (
                  <EmptyState
                    onClick={exposeAllWidgets}
                    className={styles['empty-state']}
                  />
                )}
            </>
          </LoadingOverlay>
        </div>
      </div>
      <Footer />
    </>
  );
};

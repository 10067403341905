import React, { FC } from 'react';
import { ReactComponent as SendIcon } from 'assets/SendIcon.svg';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  text: string;
  disabled?: boolean;
  onClick(text: string): void;
}

export const SuggestionCard: FC<Props> = ({ text, onClick, disabled }) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick(text);
  };

  return (
    <div
      onClick={handleClick}
      className={cn(styles.container, disabled && styles.disabled)}
    >
      {text}

      <div className={styles.send}>
        <SendIcon />
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import styles from './styles.module.scss';

export const ToastProvider: FC = () => (
  <ToastContainer
    hideProgressBar
    closeButton={false}
    draggablePercent={30}
    position="bottom-left"
    className={styles.toast}
    theme="light"
  />
);

import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { DashboardParams } from 'entities/DashboardParams.entity';

import { Histogram } from './Histogram';

import styles from './styles.module.scss';

interface Props {
  data: DashboardParams;
}

const width = 960;
const height = 260;

export const HistogramChart: FC<Props> = ({ data }) => {
  const histogramContainerRef = useRef<HTMLDivElement | null>(null);
  const [svgWidth, setSvgWidth] = useState(width);
  const [svgHeight, setSvgHeight] = useState(height);

  const { t } = useTranslation();

  const handleResize = () => {
    if (histogramContainerRef?.current) {
      setSvgHeight(histogramContainerRef.current.clientHeight);
      setSvgWidth(histogramContainerRef.current.clientWidth);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className={styles.container}>
      <CommonTooltip
        title={data?.title || t('Page.Dashboard.Histogram.Title')}
        className={cn(styles.title, 'overflowed-text-multiline')}
      />
      <span className={styles.subtitle}>
        {t('Page.Dashboard.Histogram.Subtitle')}
      </span>
      {data?.data?.length ? (
        <div
          className={styles['histogram-chart-container']}
          ref={histogramContainerRef}
        >
          <Histogram width={svgWidth} height={svgHeight} data={data} />
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </div>
  );
};

import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import cn from 'classnames';
import { CommonTooltip } from 'components';
import { DashboardCompany } from 'entities/DashboardCompany.entity';

import { ImageModal } from '../ImageModal';
import { MachineModal } from '../MachineModal';
import { MachineParkModal } from '../MachineParkModal';

import styles from './styles.module.scss';

enum InfoItems {
  industries = 'industries',
  materials = 'materials',
  certification = 'certification',
  technologies = 'technologies'
}

enum ContactItems {
  websiteUrl = 'website_url',
  phoneNumber = 'phone_numbers',
  companyEmail = 'emails',
  numberOfEmployees = 'number_of_employees',
  foundationYear = 'foundation_year'
}

const ContactLabels = Object.freeze({
  [ContactItems.websiteUrl]: 'Page.Dashboard.ManufactureProfile.Website',
  [ContactItems.phoneNumber]: 'Page.Dashboard.ManufactureProfile.PhoneNumber',
  [ContactItems.companyEmail]: 'Page.Dashboard.ManufactureProfile.CompanyEmail',
  [ContactItems.numberOfEmployees]:
    'Page.Dashboard.ManufactureProfile.NumberOfEmployees',
  [ContactItems.foundationYear]:
    'Page.Dashboard.ManufactureProfile.DateOfEstablishment'
});

const InfoLabels = Object.freeze({
  [InfoItems.industries]: 'Page.Dashboard.ManufactureProfile.Industries',
  [InfoItems.materials]: 'Page.Dashboard.ManufactureProfile.Materials',
  [InfoItems.certification]: 'Page.Dashboard.ManufactureProfile.Certification',
  [InfoItems.technologies]: 'Page.Dashboard.ManufactureProfile.Technologies'
});

const infoRows = [
  InfoItems.industries,
  InfoItems.materials,
  InfoItems.certification,
  InfoItems.technologies
];

interface Props {
  data: DashboardCompany;
  isDashboardAccessible?: boolean;
  className?: string;
}

export const ManufactureCard: FC<Props> = ({
  data,
  className,
  isDashboardAccessible
}) => {
  const { t } = useTranslation();

  const cardRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showMachinesParkModal, setShowMachinesParkModal] =
    useState<boolean>(false);
  const [zoomedInImage, setZoomedInImage] = useState<string>('');
  const [processedImages, setProcessedImages] = useState<string[]>([]);

  const closeDetailsModal = () => setShowDetails(false);
  const openDetailsModal = () => setShowDetails(true);
  const closeImageModal = () => setZoomedInImage('');

  const closeMachinesParkModal = () => setShowMachinesParkModal(false);
  const openMachinesParkModal = () => setShowMachinesParkModal(true);

  const { machines } = data;
  const machineDetails = machines?.[0]?.details;
  const machineDetailsRows = useMemo(
    () =>
      machineDetails
        ? Object.entries(machineDetails)
            .filter(([, value]) => value)
            .map(([key, value]) => ({
              label: key,
              value: value ? value.toString() : ''
            }))
        : [],
    [machineDetails]
  );

  const machineCommonDetailsRows = useMemo(
    () => [
      {
        title: t('Page.Dashboard.ManufactureProfile.Machines'),
        value: data.machines?.[0]?.brand || '-'
      },
      {
        title: t('Page.Dashboard.ManufactureProfile.Type'),
        value: data.machines?.[0]?.model || '-',
        onClick: openDetailsModal,
        className: cn(
          styles.type,
          !machineDetailsRows.length && styles['no-data']
        )
      },
      {
        title: t('Page.Dashboard.ManufactureProfile.Amount'),
        value: data.machines?.[0]?.amount || '-'
      }
    ],
    [data.machines, machineDetailsRows.length, t]
  );

  useEffect(() => {
    const element = cardRef.current;

    if (element) {
      setHasScroll(
        element.scrollHeight > element.getBoundingClientRect().height
      );
    }
  }, []);

  const convertImageToBase64 = async (url: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);
        reader.onerror = () => resolve(url);
        reader.readAsDataURL(blob);
      });
    } catch {
      return Promise.resolve(url);
    }
  };

  const fetchImages = async () => {
    const convertedImages = (await Promise.all(
      (data.images || []).map(convertImageToBase64)
    )) as string[];

    setProcessedImages(convertedImages);
  };

  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.images]);

  const renderList = (data: Array<string>) => (
    <div
      className={cn(
        styles['cell-value'],
        styles.list,
        !isDashboardAccessible && styles.hidden
      )}
    >
      {data.map((item) => (
        <CommonTooltip
          key={item}
          title={item}
          className={cn(styles.item, 'overflowed-text')}
        />
      ))}
    </div>
  );

  const contactRows = [
    {
      title: ContactLabels.website_url,
      value: data.website_url,
      renderCell: (data: DashboardCompany['website_url']) => (
        <Link
          to={`https://${data}` || ''}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={cn(
            styles['cell-value'],
            styles.link,
            !isDashboardAccessible && styles.hidden
          )}
        >
          {data}
        </Link>
      )
    },
    {
      title: ContactLabels.phone_numbers,
      value: data.phone_numbers,
      renderCell: (data: DashboardCompany['phone_numbers']) =>
        renderList(data || [])
    },
    {
      title: ContactLabels.emails,
      value: data.emails,
      renderCell: (data: DashboardCompany['emails']) => renderList(data || [])
    },
    {
      title: ContactLabels.foundation_year,
      value: data.foundation_year
    },
    {
      title: ContactLabels.number_of_employees,
      value: data.number_of_employees
    }
  ];

  return (
    <>
      <ImageModal
        isOpen={!!zoomedInImage}
        imageSrc={zoomedInImage}
        onClose={closeImageModal}
      />

      <div
        ref={cardRef}
        className={cn(styles.card, hasScroll && styles.overflowed, className)}
      >
        {!!machineDetailsRows.length && machineDetails && (
          <MachineModal
            isOpen={showDetails}
            data={machineDetailsRows}
            title={machines?.[0]?.model}
            onClose={closeDetailsModal}
          />
        )}
        {!!machines?.length && (
          <MachineParkModal
            data={machines}
            isOpen={showMachinesParkModal}
            onClose={closeMachinesParkModal}
          />
        )}
        <div className={styles.heading}>
          <CommonTooltip
            title={data.name}
            className={cn(styles.title, 'overflowed-text-multiline')}
          />
        </div>
        <div className={styles.address}>
          <div className={styles['address-cell']}>
            <span className={styles.title}>
              {t('Page.Dashboard.ManufactureProfile.Address')}
            </span>
            <span
              className={cn(
                styles['cell-value'],
                !isDashboardAccessible && styles.hidden
              )}
            >
              <span className={styles.location}>
                <LocationOnOutlinedIcon className={styles.icon} />
                <span className="overflowed-text">{data.location}</span>
              </span>
            </span>
            <span
              className={cn(
                styles['cell-value'],
                !isDashboardAccessible && styles.hidden
              )}
            >
              {data.address}
            </span>
          </div>
          <div className={styles.cells}>
            {contactRows
              .filter((row) => row.value)
              .map((row) => (
                <div key={row.title} className={styles.cell}>
                  <span className={styles.title}>{t(row.title)}</span>
                  {row.renderCell ? (
                    // @ts-expect-error row-type
                    row.renderCell(row.value)
                  ) : (
                    <span
                      className={cn(
                        styles['cell-value'],
                        !isDashboardAccessible && styles.hidden
                      )}
                    >
                      {row.value}
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.info}>
          {infoRows.map((row) => (
            <div key={row} className={styles.row}>
              <span className={styles.label}>{t(InfoLabels[row])}</span>
              <span className={styles.value}>
                {data[row] ? data[row]?.join(', ') : '-'}
              </span>
            </div>
          ))}
        </div>
        <div className={styles.separator} />
        <div className={styles.details}>
          <div className={styles['details-content']}>
            {data.machines?.length ? (
              <div className={styles.cells}>
                {machineCommonDetailsRows.map((cell) => (
                  <div key={cell.title} className={styles.cell}>
                    <span className={styles.title}>{cell.title}</span>
                    <span
                      className={cn(styles['cell-value'], cell.className)}
                      onClick={cell.onClick}
                    >
                      {cell.value}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <span className={styles.note}>
                {t('Page.Dashboard.ManufactureProfile.NoMachines')}
              </span>
            )}
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <p
            onClick={openMachinesParkModal}
            className={cn(
              styles.note,
              !data.n_machines && styles.hidden,
              machines?.length && styles['has-data']
            )}
          >
            {t('Page.Dashboard.ManufactureProfile.MachineParkLink', {
              amount: data.n_machines
            })}
          </p>
        </div>
        <div className={styles.separator} />
        <div className={styles['images-container']}>
          <div className={styles['image-title']}>
            {t('Page.Dashboard.ManufactureProfile.MachinePartsImages')}
          </div>
          <div className={styles.images}>
            {(processedImages || []).map((image, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${image}-${index}`}
                className={cn(
                  styles.image,
                  !isDashboardAccessible && index && styles.hidden
                )}
                onClick={() => setZoomedInImage(image)}
                style={{ backgroundImage: `url(${image})` }}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

import { useMutation } from '@tanstack/react-query';
import { fetchDashboardByMessageId } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';
import { queryClient } from 'index';

import { useSnackbar } from '../useSnackbar';
import { useQuestionsByChatId } from './useQuestionsByChatId';

export const useFetchDashboardData = (chatId: string) => {
  const snackbar = useSnackbar();
  const { mutate: fetchQuestionsByChatId } = useQuestionsByChatId(
    chatId,
    (data) => {
      queryClient.setQueryData(queryKeys.chatQuestions(chatId), data);
    }
  );

  return useMutation<DashboardData, ApiError, { messageId: string }>({
    mutationFn: ({ messageId }) => fetchDashboardByMessageId(chatId, messageId),
    onSuccess: (data, { messageId }) => {
      queryClient.setQueryData(queryKeys.dashboardDetails(messageId!), data);

      const isChatMessagesExists = queryClient.getQueryData(
        queryKeys.chatQuestions(chatId)
      );

      if (isChatMessagesExists) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.chatQuestions(chatId)
        });
        fetchQuestionsByChatId(chatId);
      }
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};

import React, { FC, useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import { AuthLayout, Card, SubTitle, Title } from 'components';
import { Routes } from 'enums/Routes';
import { useRestorePassword } from 'hooks/api/useRestorePassword';
import { validateEmail, validateRequired } from 'utils/helpers/validators';

import styles from './styles.module.scss';

export type ForgotPasswordFormData = {
  email: string;
};

export const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const { mutate: handleRestorePassword, isPending } = useRestorePassword();

  const methods = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email: ''
    }
  });

  const onSubmit = useCallback(
    ({ email }: ForgotPasswordFormData) => {
      const trimedEmail = email.trim();
      handleRestorePassword({ email: trimedEmail });
    },
    [handleRestorePassword]
  );

  return (
    <AuthLayout>
      <Card>
        <Title
          className={styles.title}
          title={t('Page.Auth.ForgotPassword.Title')}
        />
        <SubTitle className={styles.subtitle}>
          {t('Page.Auth.ForgotPassword.Subtitle')}
        </SubTitle>

        <FormProvider {...methods}>
          <form
            className={styles.container}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className={styles.label}>{t('Form.Label.Email')}</div>
            <Controller
              name="email"
              rules={{
                validate: (value: ForgotPasswordFormData['email']) => {
                  const trimmedEmail = value.trim();
                  if (!validateRequired(trimmedEmail)) {
                    return t('Form.Validation.Email.Required');
                  }

                  if (!validateEmail(trimmedEmail)) {
                    return t('Form.Validation.Email.NotValid');
                  }

                  return true;
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  id="email"
                  size="medium"
                  placeholder={t('Form.Placeholder.Email')}
                  error={!!methods.formState.errors.email}
                  helperText={methods.formState.errors.email?.message}
                  className={styles['input-container']}
                  inputProps={{
                    className: styles.input
                  }}
                />
              )}
            />
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              loading={isPending}
              disabled={isPending}
              className={styles.submit}
            >
              {t('Page.Auth.ForgotPassword.Submit')}
            </LoadingButton>
          </form>
        </FormProvider>

        <SubTitle className={styles['footer-subtitle']}>
          {t('Page.Auth.ForgotPassword.RememberPassword')}

          <Link className={styles['main-link']} to={Routes.Login}>
            {t('Page.Auth.ForgotPassword.ReturnToLogin')}
          </Link>
        </SubTitle>
      </Card>
    </AuthLayout>
  );
};

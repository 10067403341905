import { MutableRefObject } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getFollowUpQuestions } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Message } from 'entities/Message.entity';
import { MessageTypes, queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

import { useAnswerFromStream } from './useAnswerFromStream';

export const useFollowupQuestions = (
  abortController: MutableRefObject<AbortController | null>
) => {
  const snackbar = useSnackbar();
  const { setAnswerStatementFromStream } = useAnswerFromStream();

  return useMutation<
    {
      id: string;
      messageId: string;
      type: MessageTypes;
      statement: string;
    } | null,
    ApiError,
    { currentChatId: string; messageId: string }
  >({
    mutationFn: ({ currentChatId, messageId }) => {
      // eslint-disable-next-line no-param-reassign
      abortController.current = new AbortController();

      return getFollowUpQuestions(
        currentChatId,
        messageId,
        abortController.current.signal,
        setAnswerStatementFromStream(currentChatId)
      );
    },
    onSuccess: (successData, { currentChatId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.chatQuestions(currentChatId || '')
      });

      if (successData) {
        queryClient.setQueryData(
          queryKeys.chatMessages(currentChatId || ''),
          (previousMessages: Message[]) =>
            (previousMessages || []).map((previousMessage) => {
              if (previousMessage.id === successData.id) {
                return {
                  ...previousMessage,
                  ...successData
                };
              }

              return previousMessage;
            })
        );
      }

      // eslint-disable-next-line no-param-reassign
      abortController.current = null;
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ReactComponent as ContactUsIcon } from 'assets/RequestMoreDataIcon.svg';
import { useContactGieniUrl } from 'hooks';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

export const ContactUs = () => {
  const { t } = useTranslation();
  const contactGieniUrl = useContactGieniUrl();

  const handleButtonClick = () => {
    mixpanel?.track(MixpanelEvents.AdWidgetClick);
  };

  return (
    <div data-html2canvas-ignore className={styles.container}>
      <ContactUsIcon />

      <h3 className={styles.title}>{t('Page.Dashboard.ContactUs.Title')}</h3>

      <span className={styles.subtitle}>
        {t('Page.Dashboard.ContactUs.Subtitle')}
      </span>

      <Button
        className={styles.button}
        color="primary"
        size="medium"
        variant="contained"
        onClick={handleButtonClick}
        href={contactGieniUrl}
      >
        {t('Page.Dashboard.ContactUs.Button')}
      </Button>
    </div>
  );
};

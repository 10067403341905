import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import { AuthLayout, Card, SubTitle, Title } from 'components';
import { GoogleButton } from 'components/GoogleButton/GoogleButton';
import { Routes } from 'enums/Routes';
import { useLandingUrl } from 'hooks';

import styles from './styles.module.scss';

export const AuthPage: FC = () => {
  const { t } = useTranslation();
  const landingUrl = useLandingUrl();

  return (
    <AuthLayout>
      <Card className={styles.card}>
        <Title
          className={styles.title}
          title={t('Page.Auth.LoginOrSignup.Title')}
        />
        <SubTitle className={styles.subtitle}>
          {t('Page.Auth.LoginOrSignup.Subtitle')}
        </SubTitle>
        <div className={styles.buttons}>
          <Link to={Routes.Registration}>
            <Button
              size="medium"
              type="submit"
              variant="contained"
              className={cn(styles.button, styles.register)}
            >
              {t('Common.Signup')}
            </Button>
          </Link>
          <Link to={Routes.Login}>
            <Button
              size="medium"
              type="submit"
              variant="contained"
              className={cn(styles.button, styles.login)}
            >
              {t('Common.Login')}
            </Button>
          </Link>
        </div>

        <GoogleButton hasDivider />

        <div className={styles.links}>
          <Link
            target="_blank"
            className={styles.link}
            to={`${landingUrl}/terms-and-conditions`}
          >
            {t('Common.TermsAndConditions')}
          </Link>
          <span className={styles.separator} />
          <Link
            target="_blank"
            className={styles.link}
            to={`${landingUrl}/privacy-policy`}
          >
            {t('Common.PrivacyPolicy')}
          </Link>
        </div>
      </Card>
    </AuthLayout>
  );
};

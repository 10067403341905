import React, { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  AuthLayout,
  Card,
  SignupForm,
  SignupFormData,
  SubTitle,
  Title
} from 'components';
import { GoogleButton } from 'components/GoogleButton';
import { Routes } from 'enums/Routes';
import { useAuthContext } from 'hooks/useAuthContext';
import { LocalStorage } from 'services/LocalStorage';
import { APP_UI_LANGUAGE, DEFAULT_PREFERRED_LANGUAGE } from 'utils/constants';

import styles from './styles.module.scss';

export const SignUp: FC = () => {
  const { t } = useTranslation();
  const { signUp, isLoading } = useAuthContext();

  const methods = useForm<SignupFormData>({
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: ''
    }
  });

  const onSubmit = useCallback(
    ({ email, password, firstName, lastName }: SignupFormData) => {
      const trimedEmail = email.trim();
      const trimedFirstName = firstName.trim();
      const trimedLastName = lastName.trim();
      const preferredLanguage: string =
        LocalStorage.getItem(APP_UI_LANGUAGE) || DEFAULT_PREFERRED_LANGUAGE;

      signUp({
        email: trimedEmail,
        password,
        firstName: trimedFirstName,
        lastName: trimedLastName,
        preferredLanguage
      });
    },
    [signUp]
  );

  return (
    <AuthLayout>
      <Card>
        <Title className={styles.title} title={t('Page.Auth.Signup.Title')} />
        <SubTitle className={styles.subtitle}>
          {t('Page.Auth.Signup.Subtitle')}
        </SubTitle>
        <FormProvider {...methods}>
          <SignupForm
            onSubmit={onSubmit}
            isLoading={isLoading}
            submitLabel={t('Page.Auth.Signup.Submit')}
          />
        </FormProvider>

        <GoogleButton hasDivider />

        <SubTitle className={styles['footer-subtitle']}>
          {t('Page.Auth.Signup.HaveAccount')}
          <Link className={styles['main-link']} to={Routes.Login}>
            {t('Common.Login')}
          </Link>
        </SubTitle>
      </Card>
    </AuthLayout>
  );
};

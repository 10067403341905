import React, { FC, useEffect, useMemo, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { InsightsType } from 'enums';
import { useIsDashboardAccessible } from 'hooks';
import { useUpdateWidgetFilter } from 'hooks/api';
import { ResponsiveContainer, Tooltip, TooltipProps, Treemap } from 'recharts';
import { shortifyNumber } from 'utils/numberUtils';

import { NameType, ValueType } from '../BarChart/BarChartTooltip';
import { DashboardComponentExtended } from '../Dashboard';
import { buildTreemapData } from './helpers';
import { Select } from './Select';
import { TreemapChartTooltip } from './TreemapChartTooltip';

import styles from './styles.module.scss';

interface Props {
  isPaid?: boolean;
  data: DashboardComponentExtended;
}

const BASE_TREEMAP_COLORS = [
  '#489894',
  '#e58b24',
  '#ff9e9a',
  '#86bdb6',
  '#fabfd1',
  '#bab0ad',
  '#f1ce64',
  '#32d575',
  '#c2a746',
  '#97eab5',
  '#a0ba59',
  '#f28e2a',
  '#a0cbe8',
  '#9d7660',
  '#a0ba59',
  '#79706e',
  '#d4a6c8',
  '#4f79a7',
  '#de932f',
  '#ff9e9a',
  '#58a14e',
  '#969494',
  '#53de8c',
  '#b6992d',
  '#8490ab',
  '#8cd17d',
  '#ffbe7d',
  '#b07aa1',
  '#d37295',
  '#e1575a'
];

export const TREEMAP_COLORS = [].concat(...Array(34).fill(BASE_TREEMAP_COLORS));

const MIN_BLOCK_SIZE = 20;
const CHAR_WIDTH = 10;
const CHAR_HEIGHT = 17;

enum TreemapInfoItems {
  brands = 'brands',
  models = 'models',
  machines = 'machines'
}

const TreemapInfoLabels = Object.freeze({
  [TreemapInfoItems.brands]: 'Page.Dashboard.Treemap.Brands',
  [TreemapInfoItems.models]: 'Page.Dashboard.Treemap.Models',
  [TreemapInfoItems.machines]: 'Page.Dashboard.Treemap.Machines'
});

export type TreemapComponentExtended = DashboardParamsData & {
  color: string;
};

const textIndentation = {
  y: 18,
  x: 8
};

const CustomizedContent = (props: any) => {
  const { depth, x, y, width, height, name, model, dataLength, color } = props;

  const nameLength = name ? name?.length : model?.length;
  const showText =
    width > MIN_BLOCK_SIZE &&
    height > MIN_BLOCK_SIZE &&
    nameLength * CHAR_WIDTH < width &&
    height > CHAR_HEIGHT;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: depth < 2 && color ? color : 'rgba(255,255,255,0)',
          stroke:
            depth === 1 ? 'rgb(51, 55, 71, 0.75)' : 'rgb(51, 55, 71, 0.35)',
          strokeWidth: depth === 1 ? 1.5 : 1,
          strokeOpacity: 1 / (depth + 1e-10)
        }}
      />
      {depth === 1 && showText && dataLength > 1 ? (
        <text
          fontSize={14}
          x={x + textIndentation.x}
          textAnchor="start"
          y={y + textIndentation.y}
          style={{
            zIndex: 10
          }}
        >
          {name}
        </text>
      ) : null}
      {depth === 2 && showText && dataLength === 1 ? (
        <text
          fontSize={12}
          x={x + textIndentation.x}
          textAnchor="start"
          y={y + textIndentation.y}
          style={{
            zIndex: 10
          }}
        >
          {name || model}
        </text>
      ) : null}
    </g>
  );
};

export const TreemapChart: FC<Props> = ({ data, isPaid }) => {
  const { messageId, chatId } = useParams();
  const { t } = useTranslation();
  const isDashboardAccessible = useIsDashboardAccessible();

  const { params, filters } = data;

  const chartData = buildTreemapData(data.type, params?.data || []);

  const filteredChartData: TreemapComponentExtended[] = chartData.filter(
    (item) => filters.includes(item.name)
  );

  const { mutate: updateWidgetsFiltersMutation } = useUpdateWidgetFilter({
    chatId: chatId || '',
    messageId: messageId || '',
    widgetId: data.id
  });

  const [filteredData, setFilteredData] = useState(filteredChartData);

  const selectOptions = useMemo(
    () =>
      chartData?.map(({ name, color }) => ({
        value: name,
        label: name,
        color
      })),
    [chartData]
  );

  useEffect(() => {
    setFilteredData(filteredChartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaid]);

  const onChangeFilteredData = (values: string[]) => {
    const filteredChartData = chartData?.filter(({ name }) =>
      values.includes(name)
    );

    setFilteredData(filteredChartData);
    updateWidgetsFiltersMutation({ filters: values });
  };

  const filteredValues = useMemo(
    () => filteredData?.map(({ name }) => name),
    [filteredData]
  );

  const totalRecordsWithParentBrand = useMemo(
    () =>
      filteredData.reduce(
        (
          acc: {
            totalModels: number;
            totalMachines: number;
          },
          widget
        ) => {
          const totalModels = widget.children?.length || 0;
          const totalMachines = widget.children?.length
            ? widget.children?.reduce((partialSum, a) => partialSum + a.size, 0)
            : 0;
          acc.totalModels += totalModels;
          acc.totalMachines += totalMachines;

          return acc;
        },
        { totalModels: 0, totalMachines: 0 }
      ),
    [filteredData]
  );

  const totalRecordsWithParentTechnologies = useMemo(() => {
    if (!params.data) {
      return {
        totalModels: 0,
        totalMachines: 0
      };
    }

    return params.data.reduce(
      (
        acc: {
          totalModels: number;
          totalMachines: number;
        },
        widget
      ) => {
        const filteredModels = widget.children?.filter(
          ({ technologies }) =>
            technologies?.some((item) => filteredValues?.includes(item))
        );

        const totalModels = filteredModels?.length || 0;

        const totalMachines = filteredModels?.length
          ? filteredModels?.reduce((partialSum, a) => partialSum + a.size, 0)
          : 0;
        acc.totalModels += totalModels;
        acc.totalMachines += totalMachines;

        return acc;
      },
      { totalModels: 0, totalMachines: 0 }
    );
  }, [filteredValues, params.data]);

  const totalRecords = useMemo(() => {
    if (data.type === InsightsType.TreemapV2 && params?.data?.length === 1) {
      return totalRecordsWithParentTechnologies;
    }
    return totalRecordsWithParentBrand;
  }, [
    data.type,
    params?.data?.length,
    totalRecordsWithParentBrand,
    totalRecordsWithParentTechnologies
  ]);

  const totalBrands = useMemo(() => {
    if (params?.data?.length === 1) {
      return 1;
    }
    return filteredData.length;
  }, [filteredData.length, params?.data?.length]);

  const renderTooltip = (props: TooltipProps<ValueType, NameType>) => (
    <TreemapChartTooltip
      {...props}
      isDashboardAccessible={!!isDashboardAccessible}
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <CommonTooltip
          className={cn(styles.title, 'overflowed-text-multiline')}
          title={data?.title || t('Page.Dashboard.Treemap.Title')}
        />
        <div data-html2canvas-ignore>
          <Select
            values={filteredValues || []}
            options={selectOptions || []}
            onChange={onChangeFilteredData}
          />
        </div>
      </div>
      {!!filteredData?.length && (
        <div
          className={cn(
            styles['info-rows'],
            !isDashboardAccessible && styles.hidden
          )}
        >
          {data.type === InsightsType.TreemapV2 && (
            <div className={styles.row}>
              <span className={styles.label}>
                {t(TreemapInfoLabels.brands)}
              </span>
              <span className={styles.value}>
                {shortifyNumber(totalBrands)}
              </span>
            </div>
          )}
          <div className={styles.row}>
            <span className={styles.label}>{t(TreemapInfoLabels.models)}</span>
            <span className={styles.value}>
              {shortifyNumber(totalRecords.totalModels)}
            </span>
          </div>
          <div className={styles.row}>
            <span className={styles.label}>
              {t(TreemapInfoLabels.machines)}
            </span>
            <span className={styles.value}>
              {shortifyNumber(totalRecords.totalMachines)}
            </span>
          </div>
        </div>
      )}
      {filteredData?.length ? (
        <div
          className={cn(
            styles.treemap,
            !isDashboardAccessible && styles.hidden
          )}
        >
          <ResponsiveContainer>
            <Treemap
              width={400}
              height={640}
              dataKey="size"
              stroke="#777a8c"
              data={filteredData}
              animationDuration={0}
              aspectRatio={4 / 3}
              content={
                <CustomizedContent
                  colors={TREEMAP_COLORS}
                  dataLength={filteredData.length}
                />
              }
            >
              <Tooltip
                cursor={false}
                content={renderTooltip}
                trigger={isMobile || isTablet ? 'click' : 'hover'}
              />
            </Treemap>
          </ResponsiveContainer>
        </div>
      ) : (
        <WidgetEmptyState className={styles['empty-state']} />
      )}
    </div>
  );
};

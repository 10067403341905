import { SignupUserData } from 'actions/authActions';

import { ApiBase } from '../ApiBase';

class AuthApi extends ApiBase {
  signUp({
    email,
    password,
    firstName,
    lastName,
    preferredLanguage
  }: SignupUserData) {
    return this.client.post<void>('/sign-up', {
      email,
      password,
      firstName,
      lastName,
      preferredLanguage
    });
  }

  signIn(email: string, password: string) {
    this.client.defaults.auth = {
      password,
      username: email
    };
    return this.client.post<void>('/sign-in');
  }

  confirmEmail(token: string) {
    return this.client.post<void>('/email-confirmation', {
      token
    });
  }

  updateEmailConfirm(token: string) {
    return this.client.post<void>('/email-changed-confirmation', {
      token
    });
  }

  resendEmail(email: string) {
    return this.client.post<void>('/resend-confirmation', {
      email
    });
  }

  restorePassword(email: string) {
    return this.client.post<void>('/restore-password', {
      email
    });
  }

  logout() {
    return this.client.post<void>('/logout');
  }

  confirmRestorePassword({
    token,
    password
  }: {
    token: string;
    password: string;
  }) {
    return this.client.post<void>('/confirm-restored-password', {
      token,
      password
    });
  }
}

const instance = new AuthApi('/auth');

export { instance as AuthApi };

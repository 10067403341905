import React, { FC, ReactNode, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import { PasswordAdornment } from 'components';
import { validateEmail, validateRequired } from 'utils/helpers/validators';

import styles from './styles.module.scss';

export type SigninFormData = {
  email: string;
  password: string;
};

interface Props {
  isLoading: boolean;
  submitLabel: string;
  children?: ReactNode;
  onSubmit: (data: SigninFormData) => void;
}

export const SigninForm: FC<Props> = ({
  onSubmit,
  isLoading,
  submitLabel,
  children
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors }
  } = useFormContext<SigninFormData>();

  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.label}>{t('Form.Label.Email')}</div>
      <Controller
        name="email"
        rules={{
          validate: (value: SigninFormData['email']) => {
            const trimmedEmail = value.trim();
            if (!validateRequired(trimmedEmail)) {
              return t('Form.Validation.Email.Required');
            }

            if (!validateEmail(trimmedEmail)) {
              return t('Form.Validation.Email.NotValid');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            id="email"
            size="medium"
            placeholder={t('Form.Placeholder.Email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            className={styles['input-container']}
            inputProps={{
              className: styles.input
            }}
          />
        )}
      />

      <div className={styles.label}>{t('Form.Label.Password')}</div>
      <Controller
        name="password"
        rules={{
          validate: (value: SigninFormData['password']) => {
            if (!validateRequired(value)) {
              return t('Form.Validation.Password.Required');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            size="medium"
            id="password"
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder={t('Form.Placeholder.Password')}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            className={styles['input-container']}
            InputProps={{
              className: styles.input,
              endAdornment: (
                <PasswordAdornment
                  isVisible={isPasswordVisible}
                  onTogglePassword={() =>
                    setPasswordVisibility((value) => !value)
                  }
                />
              )
            }}
          />
        )}
      />
      {children}
      <LoadingButton
        size="medium"
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        className={styles.submit}
      >
        {submitLabel}
      </LoadingButton>
    </form>
  );
};

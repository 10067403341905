import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useAuthContext } from 'hooks/useAuthContext';

export interface ProtectedRouteProps {
  redirectPath?: string;
  forAuthenticated?: boolean;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  redirectPath = '/',
  forAuthenticated = true
}) => {
  const { isAuthenticated } = useAuthContext();

  const shouldRedirect = forAuthenticated ? !isAuthenticated : isAuthenticated;

  return shouldRedirect ? <Navigate replace to={redirectPath} /> : <Outlet />;
};

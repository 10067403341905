import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { UserApi } from 'services/API/User/UserApi';

export interface UpdateUserInfoData {
  firstName?: string;
  lastName?: string;
  email?: string;
  preferredLanguage?: string;
  isFirstLogin?: boolean;
  showReleaseNotes?: boolean;
}

export interface PasswordData {
  oldPassword: string;
  newPassword: string;
}

export const fetchUserInfo = async (): Promise<User> => {
  try {
    const response = await UserApi.fetchUserInfo();

    return User.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchUserAvatar = async (): Promise<Blob> => {
  try {
    const response = await UserApi.fetchUserAvatar();

    return response.data;
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const deleteUserAvatar = async (): Promise<void> => {
  try {
    await UserApi.deleteUserAvatar();
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateUserAvatar = async (file: FormData): Promise<void> => {
  try {
    await UserApi.updateUserAvatar(file);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateUserInfo = async (
  userInfo: UpdateUserInfoData
): Promise<User> => {
  try {
    const response = await UserApi.updateUserInfo(userInfo);

    return User.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const activateAccount = async (): Promise<User> => {
  try {
    const response = await UserApi.activateAccount();

    return User.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const deactivateAccount = async (): Promise<User> => {
  try {
    const response = await UserApi.deactivateAccount();

    return User.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const changePassword = async (
  passwordData: PasswordData
): Promise<void> => {
  try {
    await UserApi.changePassword(passwordData);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

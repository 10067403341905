import { Dispatch, SetStateAction } from 'react';
import { format } from 'date-fns';
import { useSnackbar } from 'hooks/useSnackbar';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const useSaveDashboard = () => {
  const snackbar = useSnackbar();

  const saveDashboard = async (
    target: HTMLElement,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    title?: string,
    createdAt?: Date
  ) => {
    try {
      const canvas = await html2canvas(target, {
        allowTaint: true,
        foreignObjectRendering: true,
        useCORS: true,
        width: target.scrollWidth,
        height: target.scrollHeight,
        windowWidth: target.scrollWidth,
        windowHeight: target.scrollHeight,
        y: -100
      });

      const base64image = canvas.toDataURL('jpeg');
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF();

      const docHeight = pdf.internal.pageSize.getHeight();
      const docWidth = pdf.internal.pageSize.getWidth();
      const documentAspectRatio = docWidth / docHeight;
      const imageAspectRatio = canvas.width / canvas.height;
      let height = docHeight;
      let width = docHeight * imageAspectRatio;

      if (documentAspectRatio < imageAspectRatio) {
        width = docWidth;
        height = docWidth / imageAspectRatio;
      }

      const marginX = (docWidth - width) / 2;
      const fileName = `${title || 'dashboard'}, ${format(
        createdAt || Date.now(),
        'Pp'
      )}`;

      pdf.addImage(base64image, 'jpeg', marginX, 0, width, height, '', 'FAST');

      pdf.save(fileName);
    } catch {
      snackbar.error.commonError();
    } finally {
      setIsLoading(false);
    }
  };

  return { saveDashboard };
};

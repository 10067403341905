import { useQuery } from '@tanstack/react-query';
import { fetchCoins } from 'actions/coinActions';
import { ApiError } from 'entities/ApiError.entity';
import { UserCoin } from 'entities/UserCoin.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { useAuthContext } from 'hooks/useAuthContext';

export const useUserCoins = () => {
  const { isAuthenticated } = useAuthContext();
  const { data: userInfo } = useUserInfo();

  return useQuery<UserCoin, ApiError>({
    queryKey: queryKeys.userCoins,
    queryFn: fetchCoins,
    refetchOnMount: false,
    enabled: isAuthenticated && !userInfo?.isPremiumUser
  });
};

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { ReactComponent as HamburgerIcon } from 'assets/HamburgerIcon.svg';
import { ReactComponent as LogoutIcon } from 'assets/LogoutIcon.svg';
import { ReactComponent as ReleaseNotesIcon } from 'assets/ReleaseNotesIcon.svg';
import cn from 'classnames';
import { Avatar } from 'components/Avatar';
import { PulsatingDot } from 'components/PulsatingDot';
import { Routes, Themes } from 'enums';
import { useUserAvatar } from 'hooks/api';
import { useLogout } from 'hooks/api/useLogout';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { useAuthContext } from 'hooks/useAuthContext';
import { useMobile } from 'hooks/useMobile';
import { ProfileTabs } from 'views/Profile/Profile';

import styles from './styles.module.scss';

type MenuOption =
  | {
      type: 'base';
      label: string;
      onClick: () => void;
      icon?: ReactElement;
      className?: string;
      hasIndicator?: boolean | null;
    }
  | {
      type: 'element';
      element: ReactElement;
    }
  | {
      type: 'divider';
    };

export const HamburgerMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userAvatarUrl, setUserAvatarUrl] = useState<string | undefined>();
  const { logOut } = useAuthContext();
  const { mutate: logOutMutation } = useLogout();
  const { data: userInfo } = useUserInfo();
  const isMobile = useMobile();
  const { data: userAvatar } = useUserAvatar();

  useEffect(() => {
    if (userAvatar) {
      const imageUrl = URL.createObjectURL(userAvatar);

      setUserAvatarUrl(imageUrl);
    } else {
      setUserAvatarUrl(undefined);
    }

    return () => {
      if (userAvatarUrl) {
        URL.revokeObjectURL(userAvatarUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAvatar]);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpgradePlanButtonClick = useCallback(() => {
    navigate(Routes.SubscriptionPlans);
    handleClose();
  }, [navigate]);

  const options = useMemo(() => {
    const baseOptions: MenuOption[] = [
      {
        type: 'base',
        icon: userInfo && (
          <Avatar
            imageUrl={userAvatarUrl}
            name={userInfo.firstName || userInfo.email}
          />
        ),
        label: t('Common.ProfileSettings'),
        onClick: () => {
          navigate(Routes.Profile.replace(':tab?', ProfileTabs.Settings));
          handleClose();
        }
      },
      {
        type: 'base',
        icon: <ReleaseNotesIcon />,
        label: t('Common.NewDevelopments'),
        hasIndicator: userInfo?.showReleaseNotes,
        onClick: () => {
          navigate(Routes.NewDevelopments);
          handleClose();
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'base',
        label: t('Common.Pricing'),
        onClick: () => {
          navigate(Routes.SubscriptionPlans);
          handleClose();
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'base',
        icon: <LogoutIcon />,
        label: t('Common.Logout'),
        className: styles.logout,
        onClick: () => {
          logOutMutation();
          logOut();
          handleClose();
        }
      }
    ];

    if (isMobile && userInfo?.isFreeUser) {
      baseOptions.unshift(
        {
          type: 'element',
          element: (
            <div className={styles['option-button-wrapper']}>
              <Button
                className={cn(styles['option-button'], 'brilliance')}
                color="primary"
                size="small"
                variant="contained"
                onClick={handleUpgradePlanButtonClick}
              >
                {t('Common.UpgradePlan')}
              </Button>
            </div>
          )
        },
        {
          type: 'divider'
        }
      );
    }

    return baseOptions;
  }, [
    handleUpgradePlanButtonClick,
    isMobile,
    logOut,
    logOutMutation,
    navigate,
    t,
    userInfo,
    userAvatarUrl
  ]);

  const renderMenuOption = (option: MenuOption) => {
    switch (option.type) {
      case 'element':
        return option.element;
      case 'divider':
        return <div className={styles.divider} />;
      default:
        return (
          <span
            onClick={option.onClick}
            data-testid="menu-option"
            className={cn(
              styles['option-label-wrapper'],
              option?.className,
              'overflowed-text'
            )}
          >
            <div className={styles['option-label']}>
              {option.icon}
              {option.label}
            </div>
            {option.hasIndicator && (
              <PulsatingDot theme={Themes.Success} size="small" />
            )}
          </span>
        );
    }
  };

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        color="primary"
        size="medium"
        variant="contained"
        onClick={handleClick}
      >
        <HamburgerIcon />
        {userInfo?.showReleaseNotes && (
          <PulsatingDot
            theme={Themes.Success}
            size="large"
            className={styles['menu-dot']}
          />
        )}
      </Button>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: styles.paper,
          list: styles.menu
        }}
      >
        {options.map((option, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem disableRipple key={index} className={styles['menu-item']}>
            {renderMenuOption(option)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import { ReactComponent as CheckmarkOrangeIcon } from 'assets/checkmark-orange.svg';
import cn from 'classnames';
import { Card } from 'components';
import { Routes, SubscriptionCycles, SubscriptionPlans } from 'enums';
import { useScheduleSubscription } from 'hooks/api';
import { getPlanCost, getPlanCycle } from 'utils/helpers/subscriptionHelpers';
import { ProfileTabs } from 'views/Profile/Profile';

import styles from './styles.module.scss';

const planData = {
  description: 'Page.Pricing.Premium.Subtitle',
  subdescription: 'Page.Pricing.Premium.Subdescription',
  features: [
    {
      title: (
        <Trans i18nKey="Page.Pricing.Premium.Subfeatures.Fifth">
          Everything in
          <span className={styles.highlighted}>Free +</span>
        </Trans>
      ),
      isCustom: true,
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Premium.Subfeatures.First',
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Premium.Subfeatures.Second',
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Premium.Subfeatures.Third',
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Premium.Subfeatures.Fourth',
      icon: <CheckmarkOrangeIcon className={styles['feature-icon']} />
    }
  ]
};

interface Props {
  plan: SubscriptionPlans;
  cycle: SubscriptionCycles;
  buttonLabel: string;
  disabled: boolean;
  hasActiveSubscription?: boolean;
  hasUnpaidSubscription?: boolean;
  tooltip?: string;
  size: 'small' | 'large';
}

const currencyTitle = '€';

export const PlanCard: FC<Props> = ({
  buttonLabel,
  disabled,
  tooltip,
  plan,
  cycle,
  size,
  hasActiveSubscription,
  hasUnpaidSubscription
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: scheduleSubscription } = useScheduleSubscription();

  const onClick = () => {
    if (hasActiveSubscription) {
      scheduleSubscription({ period: cycle });
    } else if (hasUnpaidSubscription) {
      navigate(Routes.Profile.replace(':tab?', ProfileTabs.Billing));
    } else {
      navigate({
        pathname: Routes.Checkout,
        search: createSearchParams({
          'billing-cycle': cycle,
          page: 'pricing'
        }).toString()
      });
    }
  };

  const isPremiumPlan = plan === SubscriptionPlans.Premium;
  const cycleTitle = getPlanCycle(plan, cycle);
  const costTitlePart = getPlanCost(plan, cycle);

  return (
    <Card className={cn(styles.card, styles[size])}>
      <div>
        <div className={styles['header-container']}>
          <h3 className={styles.title}>
            {t('Common.SubscriptionPlans.Premium')}
          </h3>
          <div className={styles['price-container']}>
            {costTitlePart != null && (
              <h3
                className={styles.price}
              >{`${currencyTitle}${costTitlePart}`}</h3>
            )}
            {isPremiumPlan && (
              <p className={styles.cycle}>{`/ ${t(cycleTitle)}`}</p>
            )}
          </div>
        </div>
        <div className={styles['description-container']}>
          <p className={styles.description}>{t(planData.description)}</p>
          <p className={styles.subdescription}>{t(planData.subdescription)}</p>
        </div>
        {planData.features.map(({ title, icon, isCustom }) => (
          <div key={title.toString()} className={styles.feature}>
            {icon}
            <span>{isCustom ? title : t(title.toString())}</span>
          </div>
        ))}
      </div>

      <Tooltip
        title={tooltip}
        enterTouchDelay={0}
        classes={{
          tooltip: styles.tooltip
        }}
      >
        <div className={styles['button-container']}>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={onClick}
            disabled={disabled}
            className={styles.button}
          >
            {buttonLabel}
          </Button>
        </div>
      </Tooltip>
    </Card>
  );
};

import React, { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import {
  AuthLayout,
  Card,
  SigninForm,
  SigninFormData,
  SubTitle,
  Title
} from 'components';
import { GoogleButton } from 'components/GoogleButton';
import { Routes } from 'enums/Routes';
import { useAuthContext } from 'hooks/useAuthContext';

import styles from './styles.module.scss';

export const SignIn: FC = () => {
  const { t } = useTranslation();
  const { signIn, isLoading } = useAuthContext();

  const methods = useForm<SigninFormData>({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = useCallback(
    ({ email, password }: SigninFormData) => {
      const trimedEmail = email.trim();
      signIn({ email: trimedEmail, password });
    },
    [signIn]
  );

  return (
    <AuthLayout>
      <Card>
        <Title className={styles.title} title={t('Page.Auth.Signin.Title')} />
        <SubTitle className={styles.subtitle}>
          {t('Page.Auth.Signin.Subtitle')}
        </SubTitle>
        <FormProvider {...methods}>
          <SigninForm
            onSubmit={onSubmit}
            isLoading={isLoading}
            submitLabel={t('Page.Auth.Signin.Submit')}
          >
            <Link
              to={Routes.ForgotPassword}
              className={cn(styles['main-link'], styles['forgot-password'])}
            >
              {t('Common.ForgotPassword')}?
            </Link>
          </SigninForm>
        </FormProvider>

        <GoogleButton hasDivider />

        <SubTitle className={styles['footer-subtitle']}>
          {t('Page.Auth.Signin.DoNotHaveAccount')}

          <Link className={styles['main-link']} to={Routes.Registration}>
            {t('Common.Signup')}
          </Link>
        </SubTitle>
      </Card>
    </AuthLayout>
  );
};

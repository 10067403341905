import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router';
import { Routes } from 'enums';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { useAuthContext } from 'hooks/useAuthContext';

export interface Props {
  redirectPath?: string;
}

export const WithVerifiedUserRoute: FC<Props> = ({
  redirectPath = Routes.VerifyUser
}) => {
  const { logOut } = useAuthContext();
  const response = useUserInfo();
  const { isError, isLoading, data } = response;

  if (isLoading) return null;

  if (isError) logOut();

  const shouldRedirect = !data?.lastName;

  return shouldRedirect ? <Navigate replace to={redirectPath} /> : <Outlet />;
};

import React, { FC, useCallback, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Collapse } from '@mui/material';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  title: string;
  description: string;
  isExpanded?: boolean;
  buttonTitle?: string;
}

export const QuestionCard: FC<Props> = ({
  title,
  description,
  isExpanded = false,
  buttonTitle
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const expandCard = useCallback(() => {
    setExpanded(true);
  }, []);

  const collapseCard = useCallback(() => setExpanded(false), []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={styles.card}>
      <div className={styles['title-container']}>
        <h3 className={styles.title}>{title}</h3>
        {expanded ? (
          <RemoveIcon className={styles.icon} onClick={collapseCard} />
        ) : (
          <AddIcon className={styles.icon} onClick={expandCard} />
        )}
      </div>
      <Collapse unmountOnExit in={expanded}>
        <div className={styles.actions}>
          <p
            className={styles.description}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {buttonTitle && (
            <Button
              size="small"
              color="primary"
              variant="contained"
              className={cn(styles.button, 'brilliance')}
              onClick={scrollToTop}
            >
              {buttonTitle}
            </Button>
          )}
        </div>
      </Collapse>
    </div>
  );
};

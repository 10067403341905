import React from 'react';
import { Link } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { CollapsableBadge } from 'components';
import { DashboardCompany } from 'entities/DashboardCompany.entity';
import { Machine } from 'entities/Machine.entity';

import styles from './styles.module.scss';

export const columns = [
  {
    width: 164,
    label: 'Page.Dashboard.ManufactureProfile.CompanyName',
    id: 'name',
    dataKey: 'name'
  },
  {
    width: 180,
    label: 'Page.Dashboard.ManufactureProfile.Address',
    id: 'address',
    dataKey: 'address',
    additionalDataKey: 'location',
    renderCell: (
      data: DashboardCompany['address'],
      additionalData?: DashboardCompany['location'],
      hideIcon?: boolean
    ) => (
      <div className={styles.address}>
        <span className={styles.country}>
          {!hideIcon && <LocationOnOutlinedIcon className={styles.icon} />}
          <span className="overflowed-text">{additionalData}</span>
        </span>
        <span>{data}</span>
      </div>
    )
  },
  {
    width: 164,
    label: 'Page.Dashboard.ManufactureProfile.Website',
    dataKey: 'website_url',
    id: 'website_url',
    renderCell: (data: DashboardCompany['website_url']) => (
      <Link
        to={`https://${data}` || ''}
        target="_blank"
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={styles.link}
      >
        {data}
      </Link>
    )
  },
  {
    width: 164,
    label: 'Page.Dashboard.ManufactureProfile.PhoneNumber',
    id: 'phone_numbers',
    dataKey: 'phone_numbers',
    renderCell: (data: DashboardCompany['phone_numbers']) => (
      <CollapsableBadge data={data || []} />
    )
  },
  {
    width: 164,
    label: 'Page.Dashboard.ManufactureProfile.CompanyEmail',
    id: 'emails',
    dataKey: 'emails',
    renderCell: (data: DashboardCompany['emails']) => (
      <CollapsableBadge data={data || []} />
    )
  },
  {
    width: 165,
    label: 'Page.Dashboard.ManufactureProfile.CountTechnologies',
    id: 'technologies_count',
    dataKey: 'technologies',
    renderCell: (data: DashboardCompany['technologies']) => data?.length
  },
  {
    width: 140,
    label: 'Page.Dashboard.ManufactureProfile.CountMachines',
    id: 'n_machines',
    dataKey: 'n_machines'
  },
  {
    width: 102,
    label: 'Page.Dashboard.ManufactureProfile.MachineTechnologies',
    id: 'technologies',
    dataKey: 'technologies',
    renderCell: (data: DashboardCompany['technologies']) => (
      <CollapsableBadge data={data || []} />
    )
  },
  {
    width: 164,
    label: 'Page.Dashboard.ManufactureProfile.Machines',
    id: 'machines',
    dataKey: 'machines',
    renderCell: (data: Machine[]) => (
      <CollapsableBadge data={data?.map(({ brand }) => brand)} />
    )
  },
  {
    width: 164,
    label: 'Page.Dashboard.ManufactureProfile.Type',
    id: 'machines_type',
    dataKey: 'machines',
    renderCell: (data: Machine[]) => (
      <CollapsableBadge data={data?.map(({ model }) => model)} />
    )
  },
  {
    width: 164,
    label: 'Page.Dashboard.ManufactureProfile.Certification',
    id: 'certification',
    dataKey: 'certification',
    renderCell: (data: DashboardCompany['certification']) => (
      <CollapsableBadge data={data || []} />
    )
  },
  {
    width: 102,
    label: 'Page.Dashboard.ManufactureProfile.Employees',
    id: 'number_of_employees',
    dataKey: 'number_of_employees'
  }
];

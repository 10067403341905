import mixpanel from 'mixpanel-browser';

const shouldInitialize = process.env.NODE_ENV === 'production';

if (shouldInitialize && process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    persistence: 'localStorage'
  });
}

export enum MixpanelEvents {
  Signin = 'Signin',
  Signup = 'Signup',
  Logout = 'Logout',
  AuthWithGoogle = 'Auth With Google',
  UpdateUserInfo = 'Update User Info',
  QuestionSubmit = 'Question Submit',
  QuestionAsked = 'Question Asked',
  DashboardGenerated = 'Dashboard Generated',
  AccountActivation = 'Account activation',
  AccountDeactivation = 'Account deactivation',
  ToggleStatusChanged = 'Toggle Status Changed',
  DownloadDashboardClick = 'Download Dashboard Click',
  ResendConfirmationEmail = 'Resend Confirmation Email',
  AnswerFeedback = 'Answer Feedback',
  WidgetFeedback = 'Widget Feedback',
  AccountCreated = 'Account Created',
  AdWidgetClick = 'Ad-Widget Click'
}

export default shouldInitialize ? mixpanel : null;

import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { ReactComponent as GieniLogo } from 'assets/logo-confirm.svg';
import { AuthLayout, Card, ErrorScreen, SubTitle, Title } from 'components';
import { Routes } from 'enums';
import { useAuthContext, useContactGieniUrl } from 'hooks';
import { useLogout, useUpdateEmailConfirm } from 'hooks/api';

import styles from './styles.module.scss';

export const UpdateEmailConfirm: FC = () => {
  const { logOut } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate: logOutMutation } = useLogout();
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get('token');

  const onSuccess = () => {
    searchParams.delete('token');
    setSearchParams(searchParams);
  };

  const {
    isError,
    isPending,
    isSuccess,
    mutate: updateEmail
  } = useUpdateEmailConfirm(token || '', onSuccess);

  const contactGieniUrl = useContactGieniUrl();

  useEffect(() => {
    if (token) {
      logOutMutation();
      logOut();
      updateEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!token && !isSuccess) {
      navigate(Routes.Auth);
    }
  }, [isSuccess, navigate, token]);

  useEffect(() => {
    if (isSuccess && searchParams.has('token')) {
      searchParams.delete('token');
      setSearchParams(searchParams);
      logOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <AuthLayout>
      <Card className={styles.card}>
        {isPending && (
          <CircularProgress
            thickness={6}
            className={styles.progress}
            data-testid="chat-progress"
          />
        )}
        {!isPending && !isError && (
          <>
            <GieniLogo className={styles.logo} />
            <Title
              className={styles.title}
              title={t('Page.Auth.UpdateEmailConfirm.Title')}
            />
            <SubTitle className={styles.subtitle}>
              {t('Page.Auth.UpdateEmailConfirm.Subtitle')}
            </SubTitle>

            <SubTitle className={styles.instructions}>
              {t('Page.Auth.UpdateEmailConfirm.Instructions')}
            </SubTitle>
            <Link to={Routes.Login} className={styles['button-container']}>
              <Button
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
              >
                {t('Page.Auth.UpdateEmailConfirm.Button')}
              </Button>
            </Link>

            <span
              className={styles['support-info']}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('Page.Auth.UpdateEmailConfirm.SupportInfo', {
                  url: contactGieniUrl
                })
              }}
            />
          </>
        )}
        {!isPending && isError && (
          <ErrorScreen
            title={t('Page.Auth.UpdateEmailConfirm.Expired.Title')}
            description={t('Page.Auth.UpdateEmailConfirm.Expired.Subtitle')}
            buttonLabel={t('Page.Auth.UpdateEmailConfirm.Expired.Button')}
            onClick={() => navigate(Routes.Login)}
          />
        )}
      </Card>
    </AuthLayout>
  );
};

import React, { ReactNode } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  loading: boolean;
  unmountOnLoading?: boolean;
}

export const LoadingOverlay: React.FC<Props> = ({
  loading,
  children,
  unmountOnLoading = false
}) => (
  <div className={styles['loading-overlay']}>
    <div
      data-testid="overlay"
      className={cn(styles.overlay, loading && styles.disabled)}
    >
      {unmountOnLoading && loading ? null : children}
    </div>
    {loading && <div data-testid="spinner" className={styles.spinner} />}
  </div>
);

import { deserialize, list, object, primitive, serializable } from 'serializr';

import { Machine } from './Machine.entity';

export class DashboardCompany {
  @serializable(list(primitive()))
  certification?: string[] | null = null;

  @serializable(list(primitive()))
  industries?: string[] | null = null;

  @serializable(list(primitive()))
  materials?: string[] | null = null;

  @serializable(list(primitive()))
  technologies?: string[] | null = null;

  @serializable(list(primitive()))
  images?: string[] | null = null;

  @serializable(list(primitive()))
  emails?: string[] | null = null;

  @serializable(list(primitive()))
  phone_numbers?: string[] | null = null;

  @serializable
  location: string = '';

  @serializable
  address?: string = '';

  @serializable
  website_url?: string = '';

  @serializable
  foundation_year?: number = 0;

  @serializable
  n_machines: number = 0;

  @serializable
  number_of_employees: number = 0;

  @serializable
  name: string = '';

  @serializable(list(object(Machine)))
  machines: Machine[] | null = null;

  static deserialize(json: Object | string): DashboardCompany {
    return deserialize(DashboardCompany, json);
  }
}

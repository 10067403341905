import { useEffect, useState } from 'react';

export const useDNDStrictMode = () => {
  const [isDragAndDropEnabled, setIsDragAndDropEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() =>
      setIsDragAndDropEnabled(true)
    );

    return () => {
      cancelAnimationFrame(animation);
      setIsDragAndDropEnabled(false);
    };
  }, []);

  return { isDragAndDropEnabled };
};

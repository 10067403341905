import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  data: Array<{ label: string; value: string }>;
}

export const MachineModal: FC<Props> = ({ isOpen, onClose, data, title }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      paperClassName={styles.modal}
    >
      <div className={styles.section}>
        <p className={styles.title}>
          {t('Page.Dashboard.ManufactureProfile.MachineDetails')}
        </p>
        <div className={styles.rows}>
          {data.map((row) => (
            <div key={row.label} className={styles.row}>
              <span className={styles.label}>{row.label}</span>
              <span className={styles.value}>{row.value}</span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

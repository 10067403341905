import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Tooltip } from 'components';

import {
  ChartPayload,
  NameType,
  ValueType
} from '../../BarChart/BarChartTooltip';

import styles from './styles.module.scss';

interface Props {
  active?: boolean;
  isDashboardAccessible?: boolean;
  payload?: ChartPayload<ValueType, NameType>[];
}

export const TreemapChartTooltip: FC<Props> = ({
  payload,
  active,
  isDashboardAccessible
}) => {
  const { t } = useTranslation();

  const data = payload?.[0]?.payload;

  if (!active || !data) {
    return null;
  }

  const { root } = data;

  return (
    <Tooltip>
      {root?.brand && (
        <div className={styles.container}>
          <span className={styles.title}>
            {t('Page.Dashboard.Treemap.Brand')}:
          </span>
          <span className={styles.subtitle}>{root?.brand}</span>
        </div>
      )}

      <div className={styles.container}>
        <span className={styles.title}>
          {t('Page.Dashboard.Treemap.Model')}:
        </span>
        <span className={styles.subtitle}>
          {payload[0].payload?.name || payload[0].payload?.model}
        </span>
      </div>

      <div className={styles.container}>
        <span className={styles.title}>
          {t('Page.Dashboard.Treemap.Quantity')}:
        </span>
        <span
          className={cn(
            styles.subtitle,
            !isDashboardAccessible && styles.hidden
          )}
        >
          {payload[0].value}
        </span>
      </div>
    </Tooltip>
  );
};

import React, { FC } from 'react';
import Switch from 'react-switch';

import './styles.scss';

interface Props {
  checkedIcon: JSX.Element;
  uncheckedIcon: JSX.Element;
  checkedHandleIcon?: JSX.Element;
  uncheckedHandleIcon?: JSX.Element;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const IconSwitch: FC<Props> = ({
  checked,
  onChange,
  checkedHandleIcon,
  uncheckedHandleIcon,
  checkedIcon,
  uncheckedIcon
}) => (
  <Switch
    onColor="#444657"
    offColor="#444657"
    offHandleColor="#636677"
    onHandleColor="#636677"
    width={76}
    height={36}
    checked={checked}
    handleDiameter={36}
    onChange={onChange}
    activeBoxShadow="0"
    checkedIcon={checkedIcon}
    uncheckedIcon={uncheckedIcon}
    checkedHandleIcon={checkedHandleIcon || uncheckedIcon}
    uncheckedHandleIcon={uncheckedHandleIcon || checkedIcon}
  />
);

import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { Transition } from 'react-transition-group';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IconButton, MobileStepper, Tooltip } from '@mui/material';
import { useUserInfo } from 'hooks/api';
import { useMobile } from 'hooks/useMobile';

import { SuggestionCard } from './SuggestionCard';

import styles from './styles.module.scss';

interface Props {
  onSuggestionClick(text: string): void;
  isVisible: boolean;
  disabled?: boolean;
}

export const Suggestions: FC<Props> = ({
  onSuggestionClick,
  isVisible,
  disabled
}) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { data: userInfo } = useUserInfo();
  const suggestionsRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const SUGGESTION_GROUPS = [
    [
      t('Page.Chat.Suggestions.Suggestion1_1'),
      t('Page.Chat.Suggestions.Suggestion1_2'),
      t('Page.Chat.Suggestions.Suggestion1_3'),
      t('Page.Chat.Suggestions.Suggestion1_4')
    ],
    [
      t('Page.Chat.Suggestions.Suggestion2_1'),
      t('Page.Chat.Suggestions.Suggestion2_2'),
      t('Page.Chat.Suggestions.Suggestion2_3'),
      t('Page.Chat.Suggestions.Suggestion2_4')
    ],
    [
      t('Page.Chat.Suggestions.Suggestion3_1'),
      t('Page.Chat.Suggestions.Suggestion3_2'),
      t('Page.Chat.Suggestions.Suggestion3_3'),
      t('Page.Chat.Suggestions.Suggestion3_4')
    ]
  ];
  const SUGGESTION_GROUPS_MOBILE = [
    [
      t('Page.Chat.Suggestions.Suggestion1_1'),
      t('Page.Chat.Suggestions.Suggestion1_2')
    ],
    [
      t('Page.Chat.Suggestions.Suggestion1_3'),
      t('Page.Chat.Suggestions.Suggestion1_4')
    ],
    [
      t('Page.Chat.Suggestions.Suggestion2_1'),
      t('Page.Chat.Suggestions.Suggestion2_2')
    ],
    [
      t('Page.Chat.Suggestions.Suggestion2_3'),
      t('Page.Chat.Suggestions.Suggestion2_4')
    ],
    [
      t('Page.Chat.Suggestions.Suggestion3_1'),
      t('Page.Chat.Suggestions.Suggestion3_2')
    ],
    [
      t('Page.Chat.Suggestions.Suggestion3_3'),
      t('Page.Chat.Suggestions.Suggestion3_4')
    ]
  ];

  const transitionStyles = {
    unmounted: {},
    entering: {},
    entered: {},
    exiting: { maxHeight: 0, opacity: 0, padding: 0, margin: 0 },
    exited: { maxHeight: 0, opacity: 0, padding: 0, margin: 0 }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const suggestionGroups = isMobile
    ? SUGGESTION_GROUPS_MOBILE
    : SUGGESTION_GROUPS;
  const maxSteps = suggestionGroups.length;

  const tooltip = useMemo(() => {
    if (userInfo?.deactivatedAt) {
      return t('Page.Chat.DeactivatedAccountTooltip');
    }
    return '';
  }, [t, userInfo?.deactivatedAt]);

  return (
    <Transition
      nodeRef={suggestionsRef}
      timeout={1000}
      enter={false}
      unmountOnExit
      in={isVisible}
    >
      {(state) => (
        <div
          className={styles.container}
          ref={suggestionsRef}
          style={{ ...transitionStyles[state] }}
        >
          <SwipeableViews
            axis="x"
            index={activeStep}
            onChangeIndex={handleStepChange}
            className={styles['suggestions-container']}
          >
            {suggestionGroups.map((suggestions, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={styles.suggestions} key={index}>
                {suggestions.map((suggestion) => (
                  <Tooltip
                    title={tooltip}
                    key={suggestion}
                    disableHoverListener={!disabled}
                    disableTouchListener={!disabled}
                    disableFocusListener={!disabled}
                    enterTouchDelay={400}
                    classes={{
                      tooltip: styles.tooltip
                    }}
                  >
                    <div>
                      <SuggestionCard
                        key={suggestion}
                        text={suggestion}
                        disabled={disabled}
                        onClick={onSuggestionClick}
                      />
                    </div>
                  </Tooltip>
                ))}
              </div>
            ))}
          </SwipeableViews>

          {!isMobile && (
            <>
              <IconButton
                size="small"
                disableRipple
                className={styles.button}
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                onClick={handleNext}
                className={styles.button}
                disabled={activeStep === maxSteps - 1}
              >
                <KeyboardArrowRight />
              </IconButton>
            </>
          )}

          <MobileStepper
            classes={{
              root: styles.stepper,
              dot: styles.dot,
              dotActive: styles['active-dot']
            }}
            steps={suggestionGroups.length}
            position="static"
            nextButton={null}
            backButton={null}
            activeStep={activeStep}
          />
        </div>
      )}
    </Transition>
  );
};

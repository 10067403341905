import React, { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import { ReactComponent as ChevronIcon } from 'assets/ChevronIcon.svg';
import cn from 'classnames';

import { CommonTooltip } from '../CommonTooltip';

import styles from './styles.module.scss';

interface Props {
  data: string[];
  className?: string;
  maxVisibleItems?: number;
}

export const CollapsableBadge: FC<Props> = ({
  data,
  maxVisibleItems = 2,
  className
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  const onBadgeClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleChange();
  };

  if (!data.length) {
    return <span>-</span>;
  }

  return (
    <div className={cn(styles['collapsable-badge'], className)}>
      <Collapse in={expanded} collapsedSize={(maxVisibleItems || 1) * 20}>
        <div className={styles.list}>
          {data.map((item, index) => (
            <CommonTooltip
              title={item}
              // eslint-disable-next-line react/no-array-index-key
              key={`${item}-${index}`}
              className={cn(styles.item, 'overflowed-text')}
            />
          ))}
        </div>
      </Collapse>
      {data.length > maxVisibleItems && (
        <div
          onClick={onBadgeClick}
          className={cn(styles.badge, expanded && styles.expanded)}
        >
          <span>{t(expanded ? 'Common.SeeLess' : 'Common.SeeMore')}</span>
          <ChevronIcon className={styles.icon} />
        </div>
      )}
    </div>
  );
};

import { custom, deserialize, serializable } from 'serializr';

export class Machine {
  @serializable
  brand: string = '';

  @serializable
  model: string = '';

  @serializable
  amount?: number = 0;

  @serializable(
    custom(
      (sourcePropertyValue: Record<string, any> | null) => {
        return sourcePropertyValue;
      },
      (value: Record<string, any> | null) => {
        return value || null;
      }
    )
  )
  details?: Record<string, any> | null = null;

  @serializable(
    custom(
      (sourcePropertyValue: Record<string, any> | null) => {
        return sourcePropertyValue;
      },
      (value: Record<string, any> | null) => {
        return value || null;
      }
    )
  )
  features?: Record<string, any> | null = null;

  static deserialize(json: Object | string): Machine {
    return deserialize(Machine, json);
  }
}

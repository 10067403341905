import React, { FC } from 'react';
import cn from 'classnames';
import { Themes } from 'enums';

import styles from './styles.module.scss';

interface Props {
  theme: Themes.Success | Themes.Error;
  size: 'small' | 'large';
  className?: string;
}

export const PulsatingDot: FC<Props> = ({ theme, size, className }) => (
  <div className={cn(styles.dot, styles[theme], styles[size], className)} />
);

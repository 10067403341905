import React, { FC } from 'react';
import { Backdrop, Fade, Modal } from '@mui/material';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  imageSrc: string;
  onClose: () => void;
}

export const ImageModal: FC<Props> = ({ isOpen, imageSrc, onClose }) =>
  isOpen ? (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={isOpen}>
        <div className={styles.modal}>
          <img
            alt="machine detail"
            src={imageSrc}
            className={styles['zoomed-image']}
          />
        </div>
      </Fade>
    </Modal>
  ) : null;

import React, { ChangeEvent, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Button, Radio } from '@mui/material';
import { ReactComponent as FeaturesCheckmarkIcon } from 'assets/FeaturesCheckmarkIcon.svg';
import cn from 'classnames';
import { Routes, SubscriptionCosts, SubscriptionCycles } from 'enums';

import styles from './styles.module.scss';

interface Props {
  isVisible: boolean;
}

const RadioIcon = ({ checked }: { checked?: boolean }) => (
  <div className={cn(styles['radio-icon'], checked && styles.checked)} />
);

export const UpgradePopup = forwardRef<HTMLDivElement, Props>(
  ({ isVisible }, ref) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [billingCycle, setBillingCycle] = useState(
      SubscriptionCycles.Monthly
    );
    const features = [
      'Page.Chat.UpgradePopup.Features.Feature1',
      'Page.Chat.UpgradePopup.Features.Feature2',
      'Page.Chat.UpgradePopup.Features.Feature3'
    ];

    const handleCycleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setBillingCycle(e.target.value as SubscriptionCycles);
    };

    const handleContinueClick = () => {
      navigate({
        pathname: Routes.Checkout,
        search: createSearchParams({
          'billing-cycle': billingCycle,
          page: 'chatbot'
        }).toString()
      });
    };

    return (
      <div
        ref={ref}
        className={cn(styles.container, isVisible && styles.visible)}
      >
        <h4 className={styles.title}>{t('Page.Chat.UpgradePopup.Title')}</h4>

        <span className={styles.description}>
          {t('Page.Chat.UpgradePopup.Description')}
        </span>

        <div className={styles.cycles}>
          <div className={styles.item}>
            <Radio
              className={styles.radio}
              icon={<RadioIcon />}
              checkedIcon={<RadioIcon checked />}
              disableRipple
              checked={billingCycle === SubscriptionCycles.Monthly}
              onChange={handleCycleChange}
              value={SubscriptionCycles.Monthly}
              name="cycle"
            />
            {t('Page.Chat.UpgradePopup.Monthly', {
              cost: SubscriptionCosts.Monthly
            })}
          </div>
          <div className={styles.item}>
            <Radio
              className={styles.radio}
              icon={<RadioIcon />}
              checkedIcon={<RadioIcon checked />}
              disableRipple
              checked={billingCycle === SubscriptionCycles.Yearly}
              onChange={handleCycleChange}
              value={SubscriptionCycles.Yearly}
              name="cycle"
            />
            {t('Page.Chat.UpgradePopup.Yearly', {
              cost: SubscriptionCosts.Yearly
            })}
            <span className={styles.badge}>
              {t('Page.Chat.UpgradePopup.Badge', { amount: 20 })}
            </span>
          </div>
        </div>

        <div className={styles.features}>
          {features.map((feature) => (
            <div key={feature} className={styles.item}>
              <FeaturesCheckmarkIcon />
              {t(feature)}
            </div>
          ))}
        </div>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleContinueClick}
          className={cn(styles.button, 'brilliance')}
        >
          {t('Page.Chat.UpgradePopup.Button')}
        </Button>
      </div>
    );
  }
);

import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchDashboardByMessageId } from 'actions/chatsActions';
import { queryKeys } from 'enums';

export const useIsDashboardAccessible = () => {
  const { messageId, chatId } = useParams();

  const { data: dashboardData } = useQuery({
    queryKey: queryKeys.dashboardDetails(messageId!),
    queryFn: () =>
      fetchDashboardByMessageId(chatId as string, messageId as string),
    refetchOnWindowFocus: false,
    enabled: !!messageId
  });

  return dashboardData?.isPaid;
};

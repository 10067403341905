import React, { FC, useState } from 'react';
import { Themes } from 'enums';
import { useMobile } from 'hooks/useMobile';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { BarChartThemeColors, barChartTickFormatter } from '../BarChart';
import { BarChartTooltip } from '../BarChartTooltip';

import './styles.scss';

interface Props {
  unit: string;
  theme: Themes.Success | Themes.Warning;
  data?: Array<{ name: string; value?: number | number[] | null }>;
}

export const VerticalBarChart: FC<Props> = ({ data = [], unit, theme }) => {
  const isMobile = useMobile();
  const [focusBarName, setFocusBarName] = useState('');

  const onMouseLeave = () => {
    if (focusBarName) {
      setFocusBarName('');
    }
  };

  return (
    <ResponsiveContainer>
      <ComposedChart
        layout="vertical"
        width={500}
        data={data}
        margin={{
          top: 10,
          right: 10,
          bottom: 0,
          left: 10
        }}
      >
        <Tooltip
          cursor={false}
          trigger="hover"
          // eslint-disable-next-line react/no-unstable-nested-components
          content={(props) => (
            <BarChartTooltip
              {...props}
              unit={unit}
              showTooltipOnFocus
              focusBarName={focusBarName}
            />
          )}
        />
        <CartesianGrid
          stroke="#8f8f8f"
          strokeDasharray="2"
          horizontal={false}
        />
        <XAxis
          tickLine={false}
          axisLine={false}
          type="number"
          tick={{
            fill: '#AFAFAF',
            fontSize: 11,
            fontFamily: 'Figtree, sans-serif'
          }}
          tickFormatter={(value) => barChartTickFormatter(value, unit)}
        />
        <YAxis
          hide={isMobile}
          tickLine={false}
          dataKey="name"
          type="category"
          tick={{
            fill: '#AFAFAF',
            fontSize: 11,
            fontFamily: 'Figtree, sans-serif',
            style: {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }
          }}
        />
        <Bar
          dataKey="value"
          barSize={24}
          fill={BarChartThemeColors[theme]}
          radius={[0, 4, 4, 0]}
          onMouseLeave={onMouseLeave}
          onMouseOut={onMouseLeave}
          onMouseMove={(state) => {
            setFocusBarName(state.name);
          }}
        >
          {(data || []).map((entry) => (
            <Cell
              key={entry.name}
              fill="#7DEAB2"
              style={{
                transition: 'all 0.2s ease-in-out'
              }}
              opacity={!focusBarName || focusBarName === entry.name ? 1 : '0.5'}
            />
          ))}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

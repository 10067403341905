import { custom, deserialize, list, object, serializable } from 'serializr';

import { BlobOptionValue } from './BlobOptionValue.entity';
import { TreemapItem } from './TreemapItem.entity';

export class DashboardParamsData {
  @serializable
  label: string = '';

  @serializable
  name: string = '';

  @serializable
  title?: string = '';

  @serializable
  brand?: string = '';

  @serializable
  bin_end?: number = 0;

  @serializable
  bin_start?: number = 0;

  @serializable
  count?: number = 0;

  @serializable(
    custom(
      (sourcePropertyValue: string) => {
        return sourcePropertyValue;
      },
      (jsonValue: Array<[number, number]>) => {
        return jsonValue;
      }
    )
  )
  value: Array<[number, number]> = [[0, 0]];

  @serializable(list(object(BlobOptionValue)))
  values?: BlobOptionValue[] | null = null;

  @serializable(list(object(TreemapItem)))
  children?: TreemapItem[] | null = null;

  static deserialize(json: Object | string): DashboardParamsData {
    return deserialize(DashboardParamsData, json);
  }
}

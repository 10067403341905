import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as ArchiveIcon } from 'assets/archive.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import cn from 'classnames';
import { Loader } from 'components';
import { Message } from 'entities/Message.entity';
import { queryKeys } from 'enums';
import { Routes } from 'enums/Routes';
import { useUserCoins, useUserInfo } from 'hooks/api';
import { useArchiveChat } from 'hooks/api/useArchiveChat';
import { useQuestionsByChatId } from 'hooks/api/useQuestionsByChatId';
import { useUpdateChat } from 'hooks/api/useUpdateChat';

import { EditableTitle } from '../EditableTitle';

import styles from './styles.module.scss';

interface Props {
  id: string;
  title: string;
  archived?: boolean;
  className?: string;
  refetch: () => void;
  isActive: boolean;
  dashboardAmount: number;
}

export const ChatCard: FC<Props> = ({
  id,
  title,
  refetch,
  className,
  archived,
  isActive,
  dashboardAmount
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [questions, setQuestions] = useState<Message[]>([]);
  const [isEditable, setEditable] = useState<boolean>(false);

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();
  const { mutate: archiveMutation } = useArchiveChat(id);
  const { isPending: isQuestionsFetching, mutate: fetchQuestionsByChatId } =
    useQuestionsByChatId(id, setQuestions);
  const { data: questionsList } = useQuery<Message[]>({
    queryKey: queryKeys.chatQuestions(id)
  });

  useEffect(() => {
    fetchQuestionsByChatId(id);
  }, [dashboardAmount, fetchQuestionsByChatId, id]);

  useEffect(() => {
    if (questionsList) {
      setQuestions(questionsList);
    }
  }, [questionsList]);

  const toggleEditable = useCallback(() => {
    setEditable((value) => !value);
  }, []);

  const onUpdateChatSuccess = async () => {
    await refetch();
    toggleEditable();
  };

  const { mutate: updateMutation } = useUpdateChat(id, onUpdateChatSuccess);

  const expandChatCard = useCallback(() => {
    setExpanded(true);
    fetchQuestionsByChatId(id);
  }, [id, fetchQuestionsByChatId]);

  const collapseChatCard = useCallback(() => setExpanded(false), []);

  const goToChat = () => {
    navigate(Routes.Chat.replace(':chatId', id));
  };

  const onToggleArchiveChat = () => {
    archiveMutation({ isArchived: !archived });
  };

  const onUpdateChat = (value: string) => {
    updateMutation({ title: value });
  };

  const renderDashboardIndicator = (isPaid?: boolean | null) => (
    <Tooltip
      enterTouchDelay={0}
      placement="right"
      classes={{
        tooltip: cn(styles.tooltip, styles['tooltip-indicator'])
      }}
      title={
        isPaid
          ? t('Common.UnlockedDashboardTooltip')
          : t('Common.LockedDashboardTooltip')
      }
    >
      <div className={cn(styles.indicator, isPaid && styles.filled)} />
    </Tooltip>
  );

  return (
    <div
      data-testid="card"
      className={cn(
        styles.card,
        isActive && styles.active,
        archived && styles.archived,
        className
      )}
    >
      <div className={styles.header}>
        {isEditable ? (
          <EditableTitle
            value={title}
            onBlur={onUpdateChat}
            label={t('Form.Placeholder.ChatName')}
            placeholder={t('Form.Placeholder.ChatName')}
          />
        ) : (
          <>
            <div
              onClick={goToChat}
              className={cn(styles.title, 'overflowed-text')}
            >
              {title}
            </div>
            <div className={styles.actions}>
              <span onClick={toggleEditable}>
                <EditIcon className={styles['action-icon']} />
              </span>
              <span data-testid="archive" onClick={onToggleArchiveChat}>
                <ArchiveIcon className={styles['action-icon']} />
              </span>
            </div>
          </>
        )}
      </div>
      {!!dashboardAmount && (
        <div className={styles.content}>
          <div className={cn(styles.label, 'overflowed-text')}>
            {t('Common.DashboardAmount', { dashboardAmount })}
          </div>
          <div
            data-testid="expand"
            onClick={expanded ? collapseChatCard : expandChatCard}
          >
            <ExpandMoreIcon
              className={cn(styles['arrow-icon'], expanded && styles.expanded)}
            />
          </div>
        </div>
      )}
      <Collapse
        unmountOnExit
        in={expanded}
        timeout={{
          enter: 0
        }}
        className={styles.rows}
      >
        {isQuestionsFetching ? (
          <Loader />
        ) : (
          (questions || []).map((question) => {
            const link = Routes.Dashboard.replace(':chatId', id).replace(
              ':messageId',
              question.messageId || ''
            );
            const isDisabledOption =
              !question.isPaid && !userCoins?.count && userInfo?.isFreeUser;

            return (
              <Tooltip
                key={question.id}
                disableFocusListener={
                  userInfo?.isPremiumUser || !!question.isPaid
                }
                disableHoverListener={
                  userInfo?.isPremiumUser || !!question.isPaid
                }
                disableTouchListener={
                  userInfo?.isPremiumUser || !!question.isPaid
                }
                enterTouchDelay={0}
                placement="bottom"
                classes={{
                  tooltip: styles.tooltip
                }}
                title={
                  userCoins?.count
                    ? t('Common.LockedDashbordChargeTooltip')
                    : t('Common.FreeSubscriptionFullDashboardButtonTooltip')
                }
              >
                <div className={styles.row}>
                  <Link
                    to={link}
                    data-testid="question"
                    className={cn(
                      styles.question,
                      isDisabledOption && styles.disabled
                    )}
                  >
                    {renderDashboardIndicator(question.isPaid)}

                    <div
                      data-testid="question"
                      className={cn(styles['question-text'], 'overflowed-text')}
                    >
                      {question.statement}
                    </div>
                  </Link>
                  <div className={styles.separator} />
                </div>
              </Tooltip>
            );
          })
        )}
      </Collapse>
    </div>
  );
};

import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
}

export const Card: FC<Props> = ({ children, className }) => (
  <div className={cn(styles.card, className)}>{children}</div>
);

import { useMutation } from '@tanstack/react-query';
import { exposeAllWidgets } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useExposeAllWidgets = (chatId: string, messageId: string) => {
  const snackbar = useSnackbar();

  return useMutation<void, ApiError>({
    mutationFn: () => {
      return exposeAllWidgets({ chatId, messageId });
    },
    onError(error) {
      snackbar.error.commonError(error);
    },
    onSuccess() {
      const prevDashboardData: DashboardData | undefined =
        queryClient.getQueryData(queryKeys.dashboardDetails(messageId));

      if (!prevDashboardData) return;

      const updatedWidgets = prevDashboardData.widgets?.map((widget) => ({
        ...widget,
        isHidden: false
      }));

      queryClient.setQueryData(queryKeys.dashboardDetails(messageId!), {
        ...prevDashboardData,
        widgets: updatedWidgets
      });
    }
  });
};

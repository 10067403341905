import { PasswordData, UpdateUserInfoData } from 'actions/userActions';
import { User } from 'entities/User.entity';

import { ApiBase } from '../ApiBase';

class UserApi extends ApiBase {
  fetchUserInfo() {
    return this.client.get<User>('');
  }

  fetchUserAvatar() {
    return this.client.get<Blob>('/avatar', {
      responseType: 'blob'
    });
  }

  deleteUserAvatar() {
    return this.client.delete<string>('/avatar');
  }

  updateUserAvatar(file: FormData) {
    return this.client.post<void>('/avatar', file, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  updateUserInfo(userInfo: UpdateUserInfoData) {
    return this.client.patch<User>('', userInfo);
  }

  changePassword(passwordInfo: PasswordData) {
    return this.client.patch<void>('/password', passwordInfo);
  }

  activateAccount() {
    return this.client.patch<User>('/activate');
  }

  deactivateAccount() {
    return this.client.patch<User>('/deactivate');
  }
}

const instance = new UserApi('/me');

export { instance as UserApi };

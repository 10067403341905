export const queryKeys = {
  updateEmailConfirm: ['updateEmailConfirm'],
  subscriptionPaymentsConfirmation: [
    'payments',
    'subscription',
    'confirmation'
  ],
  oneTimePaymentConfirmation: ['payments', 'oneTime', 'confirmation'],
  subscriptions: ['subscriptions'],
  activeSubscriptionDetails: ['subscription', 'active'],
  userInfo: ['user', 'info'],
  userAvatar: ['user', 'avatar'],
  userCoins: ['user', 'coins'],
  chats: ['chats'],
  filteredChats: (filters: { includeArchived: boolean }) => [
    ...queryKeys.chats,
    { filters }
  ],
  dashboard: ['dashboard'],
  messages: ['messages'],
  questions: ['questions'],
  dashboardDetails: (messageId: string): string[] => [
    ...queryKeys.dashboard,
    'details',
    messageId
  ],
  chatMessages: (chatId: string): string[] => [
    ...queryKeys.chats,
    chatId,
    ...queryKeys.messages
  ],
  chatQuestions: (chatId: string): string[] => [
    ...queryKeys.chats,
    chatId,
    ...queryKeys.questions
  ]
};

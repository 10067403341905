import React, {
  type FC,
  type MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Chip, Menu, Tooltip } from '@mui/material';
import { ReactComponent as ChevronIcon } from 'assets/ChevronIcon.svg';
import { ReactComponent as ResizeWidgetIcon } from 'assets/ResizeWidgetIcon.svg';
import cn from 'classnames';
import {
  BigNumberUnits,
  BigNumberUnitsLabels,
  ChartUnitsLabels,
  ChartUnitTypes,
  InsightsType,
  InsightsTypeLabels
} from 'enums';
import { useMobile } from 'hooks/useMobile';

import { DashboardComponentExtended, DashboardWidgets } from '../Dashboard';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  data: DashboardWidgets;
  onShowHiddenWidget: (card: DashboardComponentExtended) => void;
}

const getDefaultWidgetName = (chip: DashboardComponentExtended) => {
  const uniqueWidgets = [
    InsightsType.Histogram,
    InsightsType.Chat,
    InsightsType.Blob,
    InsightsType.MapDots,
    InsightsType.Treemap,
    InsightsType.TreemapV2,
    InsightsType.BarChart,
    InsightsType.ManufacturerProfiles
  ];

  if (uniqueWidgets.includes(chip.type)) {
    return InsightsTypeLabels[chip.type];
  }

  if (chip.type === InsightsType.BigNumber) {
    const unit: BigNumberUnits =
      chip.params.unit || BigNumberUnits.Manufacturers;

    return BigNumberUnitsLabels[unit];
  }

  if (chip.type === InsightsType.PieChart) {
    const unit: ChartUnitTypes =
      chip.params.type || ChartUnitTypes.Technologies;

    return ChartUnitsLabels[unit];
  }

  return '';
};

export const Filters: FC<Props> = ({ className, data, onShowHiddenWidget }) => {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const chipsContainerRef = useRef<HTMLDivElement | null>(null);
  const [isChevronVisible, setIsChevronVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    null | HTMLElement | SVGSVGElement | boolean
  >(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLButtonElement | SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleFiltersVisibility = () => setAnchorEl((prev) => !prev);

  useEffect(() => {
    if (!isMobile) {
      setIsChevronVisible(
        chipsContainerRef.current?.offsetHeight !==
          chipsContainerRef.current?.scrollHeight
      );
    }
  }, [data, isMobile]);

  const chips = useMemo(
    () =>
      Object.values(data).map((chips) =>
        chips.map((chip: DashboardComponentExtended) => (
          <Chip
            key={chip.id}
            className={styles.chip}
            label={chip.params.name || t(getDefaultWidgetName(chip))}
            deleteIcon={
              <Tooltip
                enterTouchDelay={0}
                classes={{
                  tooltip: styles.tooltip
                }}
                title={t('Page.Dashboard.MakeVisibleWidgetHint')}
              >
                <ResizeWidgetIcon />
              </Tooltip>
            }
            onDelete={() => onShowHiddenWidget(chip)}
          />
        ))
      ),
    [t, data, onShowHiddenWidget]
  );

  const hasData = Object.values(data).some((item) => item.length);

  return (
    <>
      {isMobile && hasData && (
        <div className={styles['filters-mobile']}>
          <Button
            disableRipple
            disableFocusRipple
            className={cn(styles.button, isMenuOpen && styles.active)}
            onClick={handleOpen}
          >
            {t('Page.Dashboard.HiddenWidgets')}
            <ChevronIcon />
          </Button>
          <Menu
            transitionDuration={0}
            anchorEl={anchorEl as HTMLButtonElement}
            open={isMenuOpen}
            onClose={handleClose}
            PopoverClasses={{
              paper: styles.paper
            }}
          >
            {chips}
          </Menu>
        </div>
      )}

      {!isMobile && (
        <div className={styles['filters-wrapper']}>
          <div
            onMouseLeave={handleClose}
            className={cn(
              styles.filters,
              isMenuOpen && styles.expanded,
              className
            )}
          >
            {hasData ? (
              <>
                <span className={styles.title}>
                  {t('Page.Dashboard.HiddenWidgets')}
                </span>
                <div
                  ref={chipsContainerRef}
                  className={styles['chips-container']}
                >
                  {chips}
                </div>
                {isChevronVisible && (
                  <ChevronIcon
                    className={styles.chevron}
                    onClick={toggleFiltersVisibility}
                  />
                )}
              </>
            ) : (
              <span className={styles.empty}>
                {t('Page.Dashboard.EmptyHiddenWidgets')}
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { Badge } from 'components/Badge';
import { addDays, format } from 'date-fns';
import { SubscriptionCycles, SubscriptionPlans, Themes } from 'enums';
import { useCancelSubscription } from 'hooks/api';
import { DAYS_BEFORE_AUTO_CANCELLATION } from 'utils/constants';
import { getPlanCost, getPlanCycle } from 'utils/helpers/subscriptionHelpers';

import styles from './styles.module.scss';

interface Props {
  renewalDate?: Date;
  plan: SubscriptionPlans;
  cycle: SubscriptionCycles;
}

export const UnpaidPlanDetails: FC<Props> = ({ plan, cycle, renewalDate }) => {
  const { t } = useTranslation();
  const { mutate: cancelSubscription, isPending } = useCancelSubscription();

  const title = useMemo(() => {
    const currencyTitlePart = '€';
    const planTitlePart =
      plan === SubscriptionPlans.Premium
        ? t('Common.SubscriptionPlans.Premium')
        : t('Common.SubscriptionPlans.Free');
    const costTitlePart = getPlanCost(plan, cycle);
    const cycleTitlePart = getPlanCycle(plan, cycle);

    return `${planTitlePart}, ${costTitlePart}${currencyTitlePart} / ${t(
      cycleTitlePart
    )}`;
  }, [plan, t, cycle]);

  const handleButtonClick = () => {
    cancelSubscription();
  };

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <Badge
          theme={Themes.Error}
          label={t('Common.SubscriptionStatuses.Unpaid')}
        />

        <span className={styles.title}>{title}</span>

        <span className={styles.note}>
          {t('Page.Profile.Billing.UnpaidSubscriptionNote', {
            date: renewalDate
              ? format(
                  addDays(renewalDate, DAYS_BEFORE_AUTO_CANCELLATION),
                  'PP'
                )
              : '-'
          })}
        </span>
      </div>
      <div className={styles.actions}>
        <LoadingButton
          fullWidth
          size="small"
          color="primary"
          variant="contained"
          loading={isPending}
          className={styles.button}
          onClick={handleButtonClick}
        >
          {t('Page.Profile.Billing.Buttons.CancelScheduledSubscription')}
        </LoadingButton>
      </div>
    </div>
  );
};

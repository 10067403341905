import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/error.svg';
import { SubTitle, Title } from 'components/index';

import styles from './styles.module.scss';

interface Props {
  title: string;
  description: string;
  buttonLabel: string;
  onClick: () => void;
}
export const ErrorScreen: FC<Props> = ({
  title,
  description,
  buttonLabel,
  onClick
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ErrorIcon />
      <Title className={styles.title} title={t(title)} />
      <SubTitle className={styles.subtitle}>{t(description)}</SubTitle>

      <Button
        size="medium"
        color="primary"
        variant="contained"
        onClick={onClick}
      >
        {t(buttonLabel)}
      </Button>
    </>
  );
};

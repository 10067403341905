import { useMutation } from '@tanstack/react-query';
import { fetchCoins } from 'actions/coinActions';
import { getSubscriptionConfirmation } from 'actions/paymentsActions';
import { fetchUserInfo } from 'actions/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { queryKeys } from 'enums';
import { queryClient } from 'index';

import { useSnackbar } from '../useSnackbar';

export const useSubscriptionConfirmation = (
  sessionId: string,
  onSuccess?: () => void
) => {
  const snackbar = useSnackbar();

  return useMutation<void, ApiError>({
    mutationFn: () => getSubscriptionConfirmation(sessionId),
    onError(error) {
      snackbar.error.commonError(error);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: queryKeys.userCoins });
      queryClient.fetchQuery({
        queryKey: queryKeys.userCoins,
        queryFn: fetchCoins
      });
      queryClient.invalidateQueries({ queryKey: queryKeys.userInfo });
      queryClient.fetchQuery({
        queryKey: queryKeys.userInfo,
        queryFn: fetchUserInfo
      });

      if (onSuccess) {
        onSuccess();
      }
    }
  });
};

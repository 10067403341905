import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { ReactComponent as ErrorIcon } from 'assets/ErrorSnackbarIcon.svg';
import { ReactComponent as SuccessIcon } from 'assets/SuccessSnackbarIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/WarningSnackbarIcon.svg';
import { ApiError } from 'entities/ApiError.entity';

export const useSnackbar = () => {
  const { t } = useTranslation();
  const successToast = (message: ToastContent, config: ToastOptions = {}) =>
    toast(message, {
      icon: <SuccessIcon />,
      ...config
    });

  const errorToast = (message: ToastContent, config: ToastOptions = {}) =>
    toast(message, {
      icon: <ErrorIcon />,
      ...config
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const warningToast = (message: ToastContent, config: ToastOptions = {}) =>
    toast(message, {
      icon: <WarningIcon />,
      ...config
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const infoToast = (message: ToastContent, config: ToastOptions = {}) =>
    toast(message, {
      icon: false,
      ...config
    });

  return {
    error: {
      commonError: (error?: ApiError | Error) => {
        errorToast(error?.message || t('Notifications.CommonError'), {
          closeButton: true
        });
      },

      emailConfirmationError: (error?: ApiError | Error) => {
        errorToast(error?.message || t('Notifications.ConfirmationFailed'), {
          closeButton: true
        });
      },

      emailNotConfirmedError: (
        error: ApiError | Error,
        handleResend: () => void
      ) => {
        errorToast(
          <div>
            {error?.message}.{' '}
            <span
              style={{
                textDecoration: 'underline'
              }}
              onClick={handleResend}
            >
              {t('Page.Auth.Signin.ResendConfirmationEmail')}
            </span>
          </div>
        );
      }
    },

    warning: {
      commonWarning: (message: string) => {
        warningToast(message, {
          closeButton: true
        });
      },

      googleResetPasswordWarning: () => {
        warningToast(t('Page.Auth.ForgotPassword.GoogleUserError'), {
          closeButton: true
        });
      }
    },

    success: {
      commonSuccess: (message: string) => {
        successToast(message, {
          closeButton: true
        });
      },

      lastAnswerCopySuccess: () => {
        successToast(t('Notifications.LastAnswerCopySuccess'));
      },

      chatArchivationSuccess: () => {
        successToast(t('Notifications.ChatArchivationSuccess'));
      },

      signupSuccess: () => {
        successToast(t('Notifications.SignupSuccess'));
      },

      confirmationEmailResendSuccess: (email: string) => {
        successToast(
          t('Notifications.ConfirmationEmailResendSuccess', { email })
        );
      },

      passwordResetSuccess: () => {
        successToast(t('Notifications.PasswordResetSendSuccess'));
      }
    }
  };
};

import { type Dispatch, type SetStateAction } from 'react';
import { useMutation } from '@tanstack/react-query';
import { signIn, SigninUserData } from 'actions/authActions';
import { HttpStatusCode } from 'axios';
import { ApiError } from 'entities/ApiError.entity';
import { Routes } from 'enums';
import { useResendEmail } from 'hooks/api/useResendEmail';
import { useSnackbar } from 'hooks/useSnackbar';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { LocalStorage } from 'services/LocalStorage';
import {
  SHOW_LOGIN_MODAL,
  SHOW_UNPAID_SUBSCRIPTION_MODAL
} from 'utils/constants';
import {
  saveUserEmailToStorage,
  setIsUserAuthenticated
} from 'utils/helpers/authHelpers';

export const useSignIn = (
  handleReset: () => void,
  setCurrentEmail: Dispatch<SetStateAction<string | undefined>>,
  setAuthenticated: Dispatch<SetStateAction<boolean>>
) => {
  const snackbar = useSnackbar();
  const { mutate: handleResend } = useResendEmail();

  return useMutation<void, ApiError, { userData: SigninUserData }>({
    mutationFn: async (data: {
      userData: SigninUserData;
      redirectUrl?: Routes;
    }) => {
      await signIn(data.userData);
      saveUserEmailToStorage(data.userData.email);
    },
    onSuccess(data, variables) {
      mixpanel?.track(MixpanelEvents.Signin, {
        distinct_id: variables.userData.email
      });

      setIsUserAuthenticated(true);
      LocalStorage.setItem(SHOW_LOGIN_MODAL, true);
      LocalStorage.setItem(SHOW_UNPAID_SUBSCRIPTION_MODAL, true);
      setAuthenticated(true);
      setCurrentEmail(variables.userData.email);
    },
    onError(error, variables) {
      const isEmailNotConfirmed =
        error?.statusCode === HttpStatusCode.Unauthorized &&
        error?.message === 'User email not confirmed';

      if (isEmailNotConfirmed) {
        const { email } = variables.userData;
        snackbar.error.emailNotConfirmedError(error, () =>
          handleResend({ email })
        );
      } else {
        snackbar.error.commonError(error);
      }

      handleReset();
    }
  });
};
